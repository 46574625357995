import { localI18n } from '~src/i18n.ts'

export const appTranslations = (): Record<string, string> => {
  const { t } = localI18n(i18nMessages)

  return {
    actionCancel: t('Abbrechen'),
    actionOk: t('Ok'),
    actionPrinterModel: t('Drucker verbinden'),
    printerOffline: t('Drucker offline'),
    printerOnline: t('Drucker verbunden'),
    printerConnecting: t('Drucker verbinden...'),
    printerFail: t('Druckerverbindung fehlgeschlagen'),
    downloadStarted: t('Download gestartet...'),
    noBtPrinters: t(
      'Es sind leider keine Bluetooth-Drucker verfügbar. Bitte prüfen Sie die Bluetooth-Verbindung zu Ihrem Drucker.',
    ),
    selectPrinterManufacturer: t('Drucker'),
    printingNotPossible: t('Verbindungsfehler beim Drucken. Drucker kann nicht erreicht werden.'),
    selectPrinterModel: t('Bitte wählen Sie ein Druckermodell'),
    notLoggedIn: t('Loggen Sie sich via dem App - Menü mit Ihren SumUp Account an'),
    sumupCridNumber: t('Kunden Nr.:'),
    sumupTransactionFailed: t('SumUp: Transaktion fehlgeschlagen'),
    sumupGeolocationRequired: t('SumUp: GeoLocation benötigt'),
    sumupInvalidParam: t('SumUp: Ungültiger Parameter'),
    sumupInvalidToken: t('SumUp: Ungültiger Token'),
    sumupNoConnectivity: t('SumUp: Keine Verbindung'),
    sumupPermissionDenied: t('SumUp: Berechtigung verweigert'),
    sumupDuplicateForeignKey: t('SumUp: Doppelter Fremdschlüssel'),
    sumupInvalidAffiliateKey: t('SumUp: Ungültiger Partnerschlüssel'),
    sumupAlreadyLoggedIn: t('SumUp: Du bist bereits eingeloggt'),
    sumupDropdownLogin: t('Sumup anmelden'),
    sumupDropdownLogout: t('Sumup abmelden'),
    sumupLogin: t('Sumup erfolgreich eingeloggt'),
    sumupLogout: t('Sumup erfolgreich abgemeldet'),
    transactionSuccess: t('SumUp Transaktion erfolgreich'),
    transactionError: t('SumUp Transaktion fehlgeschlagen'),
    loadingScreenLabel: t('helloCash - deine Registrierkasse'),
    noInternet: t('Keine Internetverbindung!'),
    back: t('Zurück'),
    connectPrinter1: t('Verbinde Drucker...'),
    connectPrinter2: t('Kann einige Sekunden dauern'),
    connectPrinter3: t(
      'Falls die Verbindung erfolgreich aufgebaut werden konnte, wird der Drucker einen Feed ausgeben',
    ),
    menu: t('Menü'),
    menuText: t('Hier kannst du dich mit deinem Drucker verbinden oder deinen SumUp Account hinterlegen'),
    download: t('Download'),
    downloadText: t('Wollen Sie die Datei herunterladen?'),
    downloadNotification: t('Datei erfolgreich heruntergeladen'),
    yes: t('Ja'),
    sunmiWelcomeMessage: t('Herzlich Willkommen!'),
    sunmiCheckoutHeader: t('Auf Wiedersehen!'),
    sunmiQrHeader: t('QR-Code scannen um den Digitalen Beleg zu erhalten'),
    sunmiGivenMoney: 'Gegeben',
    sunmiReturnMoney: 'Retourgeld',
    sunmiDiscountLess: 'abzüglich',
    sunmiDiscount: 'Rabatt',
    vivaWalletError: t('Kartenzahlung konnte nicht gestartet werden'),
    vivaWalletTransactionSuccess: t('Transaktion erfolgreich'),
    vivaWalletTransactionError: t('Transaktion fehlgeschlagen'),
    nfcError: t('NFC in den Einstellungen aktivieren'),
    scannerSuccess: t('Erfolgreich gescannt'),
    missingCameraPermission: t('Bitte geben Sie der Kamera die notwendige Berechtigung'),
    scannerHint: t('Barcode scannen'),
    scannerError: t('Scanner Error'),
    clearPrinterQueue: t('Druckwarteschlange leeren'),
  }
}

// generated by script
export const i18nMessages = {
  'de-AT': {
    Abbrechen: 'Abbrechen',
    Ok: 'Ok',
    'Drucker verbinden': 'Drucker verbinden',
    'Drucker offline': 'Drucker offline',
    'Drucker verbunden': 'Drucker verbunden',
    'Drucker verbinden...': 'Drucker verbinden...',
    'Druckerverbindung fehlgeschlagen': 'Druckerverbindung fehlgeschlagen',
    'Download gestartet...': 'Download gestartet...',
    'Es sind leider keine Bluetooth-Drucker verfügbar. Bitte prüfen Sie die Bluetooth-Verbindung zu Ihrem Drucker.':
      'Es sind leider keine Bluetooth-Drucker verfügbar. Bitte prüfen Sie die Bluetooth-Verbindung zu Ihrem Drucker.',
    Drucker: 'Drucker',
    'Verbindungsfehler beim Drucken. Drucker kann nicht erreicht werden.':
      'Verbindungsfehler beim Drucken. Drucker kann nicht erreicht werden.',
    'Bitte wählen Sie ein Druckermodell': 'Bitte wählen Sie ein Druckermodell',
    'Loggen Sie sich via dem App - Menü mit Ihren SumUp Account an':
      'Loggen Sie sich via dem App - Menü mit Ihren SumUp Account an',
    'Kunden Nr.:': 'Kunden Nr.:',
    'SumUp: Transaktion fehlgeschlagen': 'SumUp: Transaktion fehlgeschlagen',
    'SumUp: GeoLocation benötigt': 'SumUp: GeoLocation benötigt',
    'SumUp: Ungültiger Parameter': 'SumUp: Ungültiger Parameter',
    'SumUp: Ungültiger Token': 'SumUp: Ungültiger Token',
    'SumUp: Keine Verbindung': 'SumUp: Keine Verbindung',
    'SumUp: Berechtigung verweigert': 'SumUp: Berechtigung verweigert',
    'SumUp: Doppelter Fremdschlüssel': 'SumUp: Doppelter Fremdschlüssel',
    'SumUp: Ungültiger Partnerschlüssel': 'SumUp: Ungültiger Partnerschlüssel',
    'SumUp: Du bist bereits eingeloggt': 'SumUp: Du bist bereits eingeloggt',
    'Sumup anmelden': 'Sumup anmelden',
    'Sumup abmelden': 'Sumup abmelden',
    'Sumup erfolgreich eingeloggt': 'Sumup erfolgreich eingeloggt',
    'Sumup erfolgreich abgemeldet': 'Sumup erfolgreich abgemeldet',
    'SumUp Transaktion erfolgreich': 'SumUp Transaktion erfolgreich',
    'SumUp Transaktion fehlgeschlagen': 'SumUp Transaktion fehlgeschlagen',
    'helloCash - deine Registrierkasse': 'helloCash - deine Registrierkasse',
    'Keine Internetverbindung!': 'Keine Internetverbindung!',
    Zurück: 'Zurück',
    'Verbinde Drucker...': 'Verbinde Drucker...',
    'Kann einige Sekunden dauern': 'Kann einige Sekunden dauern',
    'Falls die Verbindung erfolgreich aufgebaut werden konnte, wird der Drucker einen Feed ausgeben':
      'Falls die Verbindung erfolgreich aufgebaut werden konnte, wird der Drucker einen Feed ausgeben',
    Menü: 'Menü',
    'Hier kannst du dich mit deinem Drucker verbinden oder deinen SumUp Account hinterlegen':
      'Hier kannst du dich mit deinem Drucker verbinden oder deinen SumUp Account hinterlegen',
    Download: 'Download',
    'Wollen Sie die Datei herunterladen?': 'Wollen Sie die Datei herunterladen?',
    'Datei erfolgreich heruntergeladen': 'Datei erfolgreich heruntergeladen',
    Ja: 'Ja',
    'Herzlich Willkommen!': 'Herzlich Willkommen!',
    'Auf Wiedersehen!': 'Auf Wiedersehen!',
    'Kartenzahlung konnte nicht gestartet werden': 'Kartenzahlung konnte nicht gestartet werden',
    'Transaktion erfolgreich': 'Transaktion erfolgreich',
    'Transaktion fehlgeschlagen': 'Transaktion fehlgeschlagen',
    'NFC in den Einstellungen aktivieren': 'NFC in den Einstellungen aktivieren',
    'Erfolgreich gescannt': 'Erfolgreich gescannt',
    'Bitte geben Sie der Kamera die notwendige Berechtigung': 'Bitte geben Sie der Kamera die notwendige Berechtigung',
    'Barcode scannen': 'Barcode scannen',
    'Scanner Error': 'Scanner Error',
    'QR-Code scannen um den Digitalen Beleg zu erhalten': 'QR-Code scannen um den Digitalen Beleg zu erhalten',
    'Druckwarteschlange leeren': 'Druckwarteschlange leeren',
  },
  'de-DE': {
    Abbrechen: 'Abbrechen',
    Ok: 'Ok',
    'Drucker verbinden': 'Drucker verbinden',
    'Drucker offline': 'Drucker offline',
    'Drucker verbunden': 'Drucker verbunden',
    'Drucker verbinden...': 'Drucker verbinden...',
    'Druckerverbindung fehlgeschlagen': 'Druckerverbindung fehlgeschlagen',
    'Download gestartet...': 'Download gestartet...',
    'Es sind leider keine Bluetooth-Drucker verfügbar. Bitte prüfen Sie die Bluetooth-Verbindung zu Ihrem Drucker.':
      'Es sind leider keine Bluetooth-Drucker verfügbar. Bitte prüfen Sie die Bluetooth-Verbindung zu Ihrem Drucker.',
    Drucker: 'Drucker',
    'Verbindungsfehler beim Drucken. Drucker kann nicht erreicht werden.':
      'Verbindungsfehler beim Drucken. Drucker kann nicht erreicht werden.',
    'Bitte wählen Sie ein Druckermodell': 'Bitte wählen Sie ein Druckermodell',
    'Loggen Sie sich via dem App - Menü mit Ihren SumUp Account an':
      'Loggen Sie sich via dem App - Menü mit Ihren SumUp Account an',
    'Kunden Nr.:': 'Kunden Nr.:',
    'SumUp: Transaktion fehlgeschlagen': 'SumUp: Transaktion fehlgeschlagen',
    'SumUp: GeoLocation benötigt': 'SumUp: GeoLocation benötigt',
    'SumUp: Ungültiger Parameter': 'SumUp: Ungültiger Parameter',
    'SumUp: Ungültiger Token': 'SumUp: Ungültiger Token',
    'SumUp: Keine Verbindung': 'SumUp: Keine Verbindung',
    'SumUp: Berechtigung verweigert': 'SumUp: Berechtigung verweigert',
    'SumUp: Doppelter Fremdschlüssel': 'SumUp: Doppelter Fremdschlüssel',
    'SumUp: Ungültiger Partnerschlüssel': 'SumUp: Ungültiger Partnerschlüssel',
    'SumUp: Du bist bereits eingeloggt': 'SumUp: Du bist bereits eingeloggt',
    'Sumup anmelden': 'Sumup anmelden',
    'Sumup abmelden': 'Sumup abmelden',
    'Sumup erfolgreich eingeloggt': 'Sumup erfolgreich eingeloggt',
    'Sumup erfolgreich abgemeldet': 'Sumup erfolgreich eingeloggt',
    'SumUp Transaktion erfolgreich': 'SumUp Transaktion erfolgreich',
    'SumUp Transaktion fehlgeschlagen': 'SumUp Transaktion fehlgeschlagen',
    'helloCash - deine Registrierkasse': 'helloCash - deine Registrierkasse',
    'Keine Internetverbindung!': 'Keine Internetverbindung!',
    Zurück: 'Zurück',
    'Verbinde Drucker...': 'Verbinde Drucker...',
    'Kann einige Sekunden dauern': 'Kann einige Sekunden dauern',
    'Falls die Verbindung erfolgreich aufgebaut werden konnte, wird der Drucker einen Feed ausgeben':
      'Falls die Verbindung erfolgreich aufgebaut werden konnte, wird der Drucker einen Feed ausgeben',
    Menü: 'Menü',
    'Hier kannst du dich mit deinem Drucker verbinden oder deinen SumUp Account hinterlegen':
      'Hier kannst du dich mit deinem Drucker verbinden oder deinen SumUp Account hinterlegen',
    Download: 'Download',
    'Wollen Sie die Datei herunterladen?': 'Wollen Sie die Datei herunterladen?',
    'Datei erfolgreich heruntergeladen': 'Datei erfolgreich heruntergeladen',
    Ja: 'Ja',
    'Herzlich Willkommen!': 'Herzlich Willkommen!',
    'Auf Wiedersehen!': 'Auf Wiedersehen!',
    'Kartenzahlung konnte nicht gestartet werden': 'Kartenzahlung konnte nicht gestartet werden',
    'Transaktion erfolgreich': 'Transaktion erfolgreich',
    'Transaktion fehlgeschlagen': 'Transaktion fehlgeschlagen',
    'NFC in den Einstellungen aktivieren': 'NFC in den Einstellungen aktivieren',
    'Erfolgreich gescannt': 'Erfolgreich gescannt',
    'Bitte geben Sie der Kamera die notwendige Berechtigung': 'Bitte geben Sie der Kamera die notwendige Berechtigung',
    'Barcode scannen': 'Barcode scannen',
    'Scanner Error': 'Scanner Error',
    'QR-Code scannen um den Digitalen Beleg zu erhalten': 'QR-Code scannen um den Digitalen Beleg zu erhalten',
    'Druckwarteschlange leeren': 'Druckwarteschlange leeren',
  },
  'cs-CZ': {
    Abbrechen: 'Zrušení',
    Ok: 'OK',
    'Drucker verbinden': 'Připojit tiskárnu',
    'Drucker offline': 'Tiskárna v režimu offline',
    'Drucker verbunden': 'Tiskárna připojena',
    'Drucker verbinden...': 'Připojte tiskárnu...',
    'Druckerverbindung fehlgeschlagen': 'Připojení tiskárny se nezdařilo',
    'Download gestartet...': 'Stahování začalo ...',
    'Es sind leider keine Bluetooth-Drucker verfügbar. Bitte prüfen Sie die Bluetooth-Verbindung zu Ihrem Drucker.':
      'K dispozici není žádná Bluetooth tiskárna. Zkontrolujte Bluetooth spojení k tiskárně.',
    Drucker: 'Tiskárny',
    'Verbindungsfehler beim Drucken. Drucker kann nicht erreicht werden.':
      'Chyba spojení při tisku. Spojení s tiskárnou se nezdařilo.',
    'Bitte wählen Sie ein Druckermodell': 'Zvolte prosím model tiskárny',
    'Loggen Sie sich via dem App - Menü mit Ihren SumUp Account an':
      'Přihlaste se prostřednictvím nabídky aplikace pomocí účtu SumUp',
    'Kunden Nr.:': 'Zákazník č.:',
    'SumUp: Transaktion fehlgeschlagen': 'SumUp Transakce selhala',
    'SumUp: GeoLocation benötigt': 'SumUp: GeoLocation potřebuje',
    'SumUp: Ungültiger Parameter': 'SumUp: Neplatný parametr',
    'SumUp: Ungültiger Token': 'SumUp: Neplatný token',
    'SumUp: Keine Verbindung': 'SumUp: Žádné připojení',
    'SumUp: Berechtigung verweigert': 'SumUp: Oprávnění bylo odepřeno',
    'SumUp: Doppelter Fremdschlüssel': 'SumUp: Dvojitý cizí klíč',
    'SumUp: Ungültiger Partnerschlüssel': 'SumUp: Neplatný přidružený klíč',
    'SumUp: Du bist bereits eingeloggt': 'SumUp: Jste již přihlášeni',
    'Sumup anmelden': 'Sumup přihlásit se',
    'Sumup abmelden': 'Sumup odhlásit se',
    'Sumup erfolgreich eingeloggt': 'Úspěšně jste přihlášeni',
    'Sumup erfolgreich abgemeldet': 'Úspěšně byl odhlášen',
    'SumUp Transaktion erfolgreich': 'Transakce SumUp proběhla úspěšně',
    'SumUp Transaktion fehlgeschlagen': 'SumUp Transakce selhala',
    'helloCash - deine Registrierkasse': 'helloCash - vaší registrační pokladny',
    'Keine Internetverbindung!': 'Bez připojení k internetu!',
    Zurück: 'Zpět',
    'Verbinde Drucker...': 'Připojení tiskárny ...',
    'Kann einige Sekunden dauern': 'Může trvat několik sekund',
    'Falls die Verbindung erfolgreich aufgebaut werden konnte, wird der Drucker einen Feed ausgeben':
      'Pokud se spojení podařilo, tiskárna vytiskne zdroj',
    Menü: 'Menu',
    'Hier kannst du dich mit deinem Drucker verbinden oder deinen SumUp Account hinterlegen':
      'Zde se můžete připojit k tiskárně nebo uložit účet SumUp',
    Download: 'Stáhnout',
    'Wollen Sie die Datei herunterladen?': 'Chcete soubor stáhnout?',
    'Datei erfolgreich heruntergeladen': 'Soubor byl úspěšně stažen',
    Ja: 'Ano',
    'Herzlich Willkommen!': 'Vřelé přivítání!',
    'Auf Wiedersehen!': 'Ahoj!',
    'Kartenzahlung konnte nicht gestartet werden': 'Platbu kartou nelze spustit',
    'Transaktion erfolgreich': 'Transakce proběhla úspěšně',
    'Transaktion fehlgeschlagen': 'Transakce selhala',
    'NFC in den Einstellungen aktivieren': 'Aktivovat NFC v nastavení',
    'Erfolgreich gescannt': 'Úspěšně naskenováno',
    'Bitte geben Sie der Kamera die notwendige Berechtigung': 'Prosím udělte potřebné oprávnění pro fotoaparát',
    'Barcode scannen': 'Skenovat čárový kód',
    'Scanner Error': 'Chyba při skenování',
    'QR-Code scannen um den Digitalen Beleg zu erhalten': 'Naskenujte QR kód a získejte digitální účtenku',
    'Druckwarteschlange leeren': 'Prázdná tisková fronta',
  },
  'fr-FR': {
    Abbrechen: 'Interrompre',
    Ok: 'OK',
    'Drucker verbinden': 'Modèle imprimante',
    'Drucker offline': 'Imprimante hors ligne',
    'Drucker verbunden': 'Imprimante en ligne',
    'Drucker verbinden...': 'Connecter une imprimante...',
    'Druckerverbindung fehlgeschlagen': "La connexion de l\\\\'imprimante a échoué",
    'Download gestartet...': 'Téléchargement lancé...',
    'Es sind leider keine Bluetooth-Drucker verfügbar. Bitte prüfen Sie die Bluetooth-Verbindung zu Ihrem Drucker.':
      "Malheureusement, aucune imprimante Bluetooth n'est disponible. Veuillez vérifier la connexion Bluetooth de votre imprimante.",
    Drucker: 'Imprimante',
    'Verbindungsfehler beim Drucken. Drucker kann nicht erreicht werden.':
      "Erreur de connexion lors de l\\\\'impression. L\\\\'imprimante ne peut pas ètre atteinte.",
    'Bitte wählen Sie ein Druckermodell': 'Veuillez choisir un modèle d imprimante',
    'Loggen Sie sich via dem App - Menü mit Ihren SumUp Account an':
      'Connectez-vous via le menu App avec votre compte SumUp',
    'Kunden Nr.:': 'N° de client :',
    'SumUp: Transaktion fehlgeschlagen': 'SumUp : La transaction a échoué',
    'SumUp: GeoLocation benötigt': 'SumUp : Géolocalisation nécessaire',
    'SumUp: Ungültiger Parameter': 'SumUp : Paramètre non valide',
    'SumUp: Ungültiger Token': 'SumUp : Jeton non valide',
    'SumUp: Keine Verbindung': 'SumUp : Pas de connexion',
    'SumUp: Berechtigung verweigert': 'SumUp : Autorisation refusée',
    'SumUp: Doppelter Fremdschlüssel': 'SumUp : Double clé étrangère',
    'SumUp: Ungültiger Partnerschlüssel': 'SumUp : Clé de partenaire non valide',
    'SumUp: Du bist bereits eingeloggt': 'SumUp : Vous êtes déjà connecté',
    'Sumup anmelden': 'Connexion sumup',
    'Sumup abmelden': 'Sumup se déconnecter',
    'Sumup erfolgreich eingeloggt': 'Connecté avec succès',
    'Sumup erfolgreich abgemeldet': 'Déconnecté avec succès',
    'SumUp Transaktion erfolgreich': 'Succès de la transaction SumUp',
    'SumUp Transaktion fehlgeschlagen': 'Échec de la transaction SumUp',
    'helloCash - deine Registrierkasse': 'helloCash - votre caisse enregistreuse',
    'Keine Internetverbindung!': 'Pas de connexion internet !',
    Zurück: 'Retour',
    'Verbinde Drucker...': "Connecter l'imprimante ...",
    'Kann einige Sekunden dauern': 'Quelques secondes peuvent être nécessaires',
    'Falls die Verbindung erfolgreich aufgebaut werden konnte, wird der Drucker einen Feed ausgeben':
      "Si la connexion a réussi, l'imprimante imprime un flux",
    Menü: 'Menu',
    'Hier kannst du dich mit deinem Drucker verbinden oder deinen SumUp Account hinterlegen':
      'Vous pouvez ici vous connecter à votre imprimante ou déposer votre compte SumUp',
    Download: 'Télécharger',
    'Wollen Sie die Datei herunterladen?': 'Voulez-vous télécharger le fichier ?',
    'Datei erfolgreich heruntergeladen': 'Fichier téléchargé avec succès',
    Ja: 'Oui',
    'Herzlich Willkommen!': 'Bienvenue !',
    'Auf Wiedersehen!': 'Au revoir !',
    'Kartenzahlung konnte nicht gestartet werden': "Le paiement par carte n'a pas pu être initié",
    'Transaktion erfolgreich': 'Transaction réussie',
    'Transaktion fehlgeschlagen': 'Échec de la transaction',
    'NFC in den Einstellungen aktivieren': 'Activer le NFC dans les paramètres',
    'Erfolgreich gescannt': 'Scanné avec succès',
    'Bitte geben Sie der Kamera die notwendige Berechtigung': "Veuillez accorder à la caméra l'autorisation nécessaire",
    'Barcode scannen': 'Scanner le code-barres',
    'Scanner Error': 'Erreur de scannage',
    'QR-Code scannen um den Digitalen Beleg zu erhalten': 'Scanner le code QR pour obtenir le reçu numérique',
    'Druckwarteschlange leeren': "Vider la file d'attente d'impression",
  },
  'en-GB': {
    Abbrechen: 'Cancel',
    Ok: 'Ok',
    'Drucker verbinden': 'Connect printer',
    'Drucker offline': 'Printer offline',
    'Drucker verbunden': 'Printer connected',
    'Drucker verbinden...': 'Connect printer ...',
    'Druckerverbindung fehlgeschlagen': 'Printer connection failed',
    'Download gestartet...': 'Download started ...',
    'Es sind leider keine Bluetooth-Drucker verfügbar. Bitte prüfen Sie die Bluetooth-Verbindung zu Ihrem Drucker.':
      'Unfortunately there are no bluetooth printers available. Please check the Bluetooth connection to your printer.',
    Drucker: 'Printer',
    'Verbindungsfehler beim Drucken. Drucker kann nicht erreicht werden.':
      'Connection error while printing. Printer cannot be reached.',
    'Bitte wählen Sie ein Druckermodell': 'Please select a printer model',
    'Loggen Sie sich via dem App - Menü mit Ihren SumUp Account an': 'Log in to your SumUp account via the app menu',
    'Kunden Nr.:': 'Customer number.:',
    'SumUp: Transaktion fehlgeschlagen': 'SumUp: transaction failed',
    'SumUp: GeoLocation benötigt': 'SumUp: GeoLocation required',
    'SumUp: Ungültiger Parameter': 'SumUp: Invalid parameter',
    'SumUp: Ungültiger Token': 'SumUp: Invalid token',
    'SumUp: Keine Verbindung': 'SumUp: No connection',
    'SumUp: Berechtigung verweigert': 'SumUp: Authorization denied',
    'SumUp: Doppelter Fremdschlüssel': 'SumUp: Duplicate Foreign Key',
    'SumUp: Ungültiger Partnerschlüssel': 'SumUp: Invalid partner key',
    'SumUp: Du bist bereits eingeloggt': 'SumUp: You are already logged in',
    'Sumup anmelden': 'Sign in Sumup',
    'Sumup abmelden': 'Sign out Sumup',
    'Sumup erfolgreich eingeloggt': 'Sumup logged in successfully',
    'Sumup erfolgreich abgemeldet': 'Sumup signed out successfully',
    'SumUp Transaktion erfolgreich': 'SumUp transaction successful',
    'SumUp Transaktion fehlgeschlagen': 'SumUp transaction failed',
    'helloCash - deine Registrierkasse': 'helloCash - your cash register',
    'Keine Internetverbindung!': 'No Internet connection!',
    Zurück: 'Back',
    'Verbinde Drucker...': 'Connect printer ...',
    'Kann einige Sekunden dauern': 'May take a few seconds',
    'Falls die Verbindung erfolgreich aufgebaut werden konnte, wird der Drucker einen Feed ausgeben':
      'If the connection could be established successfully, the printer will output a feed',
    Menü: 'Menu',
    'Hier kannst du dich mit deinem Drucker verbinden oder deinen SumUp Account hinterlegen':
      'Here you can connect to your printer or save your SumUp account',
    Download: 'Download',
    'Wollen Sie die Datei herunterladen?': 'Do you want to download the file?',
    'Datei erfolgreich heruntergeladen': 'File downloaded successfully',
    Ja: 'Yes',
    'Herzlich Willkommen!': 'Welcome!',
    'Auf Wiedersehen!': 'Goodbye!',
    'Kartenzahlung konnte nicht gestartet werden': 'Card payment could not be started',
    'Transaktion erfolgreich': 'Transaction successfully',
    'Transaktion fehlgeschlagen': 'Transaction failed',
    'NFC in den Einstellungen aktivieren': 'Activate NFC in the settings',
    'Erfolgreich gescannt': 'Successfully scanned',
    'Bitte geben Sie der Kamera die notwendige Berechtigung': 'Please give the camera the necessary permission',
    'Barcode scannen': 'Scan barcode',
    'Scanner Error': 'Scan error',
    'QR-Code scannen um den Digitalen Beleg zu erhalten': 'Scan QR code to get the digital receipt',
    'Druckwarteschlange leeren': 'Empty print queue',
  },
  'es-ES': {
    Abbrechen: 'Cancelar',
    Ok: 'Ok',
    'Drucker verbinden': 'Modelo de impresora',
    'Drucker offline': 'Impresora fuera de línea',
    'Drucker verbunden': 'Impresora en línea',
    'Drucker verbinden...': 'Conectar impresora...',
    'Druckerverbindung fehlgeschlagen': 'Falló la conexión de la impresora',
    'Download gestartet...': 'Descarga iniciada...',
    'Es sind leider keine Bluetooth-Drucker verfügbar. Bitte prüfen Sie die Bluetooth-Verbindung zu Ihrem Drucker.':
      'Lo sentimos, no hay impresoras Bluetooth disponibles. Por favor, compruebe la conexión Bluetooth de su impresora.',
    Drucker: 'Impresora',
    'Verbindungsfehler beim Drucken. Drucker kann nicht erreicht werden.':
      'Error de conexión durante la impresión. No se puede acceder a la impresora.',
    'Bitte wählen Sie ein Druckermodell': 'Por favor, seleccione un modelo de impresora',
    'Loggen Sie sich via dem App - Menü mit Ihren SumUp Account an':
      'Inicie sesión a través de la App - Menú con su cuenta SumUp',
    'Kunden Nr.:': 'Nº de cliente.:',
    'SumUp: Transaktion fehlgeschlagen': 'SumUp: Transacción fallida',
    'SumUp: GeoLocation benötigt': 'SumUp: GeoLocation necesario',
    'SumUp: Ungültiger Parameter': 'SumUp: Parámetros no válidos',
    'SumUp: Ungültiger Token': 'SumUp: Token no válido',
    'SumUp: Keine Verbindung': 'SumUp: Sin conexión',
    'SumUp: Berechtigung verweigert': 'SumUp: Autorización denegada',
    'SumUp: Doppelter Fremdschlüssel': 'SumUp: Doble clave externa',
    'SumUp: Ungültiger Partnerschlüssel': 'SumUp: Clave de socio no válida',
    'SumUp: Du bist bereits eingeloggt': 'SumUp: Ya has iniciado sesión',
    'Sumup anmelden': 'Sumup iniciar sesión',
    'Sumup abmelden': 'Sumup cerrar sesión',
    'Sumup erfolgreich eingeloggt': 'Ha iniciado sesión correctamente',
    'Sumup erfolgreich abgemeldet': 'Desconectada con éxito',
    'SumUp Transaktion erfolgreich': 'La transacción SumUp se ha realizado con éxito',
    'SumUp Transaktion fehlgeschlagen': 'Transacción SumUp fallida',
    'helloCash - deine Registrierkasse': 'helloCash - su caja registradora',
    'Keine Internetverbindung!': 'No hay conexión a internet!',
    Zurück: 'Volver',
    'Verbinde Drucker...': 'Conectar impresora ...',
    'Kann einige Sekunden dauern': '(puede tomar unos segundos)',
    'Falls die Verbindung erfolgreich aufgebaut werden konnte, wird der Drucker einen Feed ausgeben':
      'Si la conexión se ha establecido correctamente, la impresora emitirá un feed',
    Menü: 'Menú',
    'Hier kannst du dich mit deinem Drucker verbinden oder deinen SumUp Account hinterlegen':
      'Aquí puede conectarse a su impresora o depositar su cuenta SumUp',
    Download: 'Descargar',
    'Wollen Sie die Datei herunterladen?': '¿Quieres descargar el archivo?',
    'Datei erfolgreich heruntergeladen': 'Archivo descargado con éxito',
    Ja: 'Sí',
    'Herzlich Willkommen!': '¡Cálida bienvenida!',
    'Auf Wiedersehen!': '¡Adiós!',
    'Kartenzahlung konnte nicht gestartet werden': 'No se ha podido iniciar el pago con tarjeta',
    'Transaktion erfolgreich': 'Transacción realizada con éxito',
    'Transaktion fehlgeschlagen': 'Transacción fallida',
    'NFC in den Einstellungen aktivieren': 'Activar NFC en los ajustes',
    'Erfolgreich gescannt': 'Escaneado con éxito',
    'Bitte geben Sie der Kamera die notwendige Berechtigung': 'Por favor, autorice la cámara',
    'Barcode scannen': 'Escanear código de barras',
    'Scanner Error': 'Error al escanear',
    'QR-Code scannen um den Digitalen Beleg zu erhalten': 'Escanee el código QR para obtener el recibo digital',
    'Druckwarteschlange leeren': 'Cola de impresión vacía',
  },
  'pl-PL': {
    Abbrechen: 'Przerwać',
    Ok: 'OK',
    'Drucker verbinden': 'Model drukarki',
    'Drucker offline': 'Drukarka online',
    'Drucker verbunden': 'Drukarka online',
    'Drucker verbinden...': 'Podłącz drukarkę...',
    'Druckerverbindung fehlgeschlagen': 'Połączenie drukarki nie powiodło się',
    'Download gestartet...': 'Rozpoczęto pobieranie...',
    'Es sind leider keine Bluetooth-Drucker verfügbar. Bitte prüfen Sie die Bluetooth-Verbindung zu Ihrem Drucker.':
      'Nie wykryto drukarki bluetooth. Sprawdź połączenie bluetooth z drukarką.',
    Drucker: 'Drukarka',
    'Verbindungsfehler beim Drucken. Drucker kann nicht erreicht werden.':
      'Błąd połączenia podczas drukowania. Brak połączenia z drukarką.',
    'Bitte wählen Sie ein Druckermodell': 'Wybierz model drukarki',
    'Loggen Sie sich via dem App - Menü mit Ihren SumUp Account an':
      'Zaloguj się przez aplikację – wpisz dane swojego konta SumUp',
    'Kunden Nr.:': 'Numer klienta:',
    'SumUp: Transaktion fehlgeschlagen': 'SumUp: Transakcja nie powiodła się',
    'SumUp: GeoLocation benötigt': 'SumUp: Potrzebna jest geolokalizacja',
    'SumUp: Ungültiger Parameter': 'SumUp: Nieprawidłowy parametr',
    'SumUp: Ungültiger Token': 'SumUp: Nieprawidłowy token',
    'SumUp: Keine Verbindung': 'SumUp: Brak połączenia',
    'SumUp: Berechtigung verweigert': 'SumUp: Odmowa autoryzacji',
    'SumUp: Doppelter Fremdschlüssel': 'SumUp: zduplikowany klucz obcy',
    'SumUp: Ungültiger Partnerschlüssel': 'SumUp: Nieprawidłowy klucz partnera',
    'SumUp: Du bist bereits eingeloggt': 'SumUp: Jesteś już zalogowany',
    'Sumup anmelden': 'Zapisz się na Sumup',
    'Sumup abmelden': 'Wyloguj się z Sumup',
    'Sumup erfolgreich eingeloggt': 'Zalogowano',
    'Sumup erfolgreich abgemeldet': 'Wylogowano',
    'SumUp Transaktion erfolgreich': 'Udana transakcja SumUp',
    'SumUp Transaktion fehlgeschlagen': 'Błędna transakcja SumUp',
    'helloCash - deine Registrierkasse': 'helloCash – Twoja kasa fiskalna',
    'Keine Internetverbindung!': 'Brak połączenia z internetem!',
    Zurück: 'Cofnij',
    'Verbinde Drucker...': 'Połącz drukarkę ...',
    'Kann einige Sekunden dauern': '(to może potrwać kilka sekund)',
    'Falls die Verbindung erfolgreich aufgebaut werden konnte, wird der Drucker einen Feed ausgeben':
      'Po udanym połączeniu drukarka zacznie drukować',
    Menü: 'Menu',
    'Hier kannst du dich mit deinem Drucker verbinden oder deinen SumUp Account hinterlegen':
      'Tutaj możesz połączyć swoją drukarkę lub załadować swoje konto SumUp',
    Download: 'Download',
    'Wollen Sie die Datei herunterladen?': 'Czy chcesz pobrać plik?',
    'Datei erfolgreich heruntergeladen': 'Plik został pobrany',
    Ja: 'Tak',
    'Herzlich Willkommen!': 'Witamy!',
    'Auf Wiedersehen!': 'Do widzenia!',
    'Kartenzahlung konnte nicht gestartet werden': 'Nie można uruchomić płatności kartą',
    'Transaktion erfolgreich': 'Transakcja zakończona sukcesem',
    'Transaktion fehlgeschlagen': 'Transakcja nie powiodła się',
    'NFC in den Einstellungen aktivieren': 'Aktywuj NFC w ustawieniach',
    'Erfolgreich gescannt': 'Zeskanowano pomyślnie',
    'Bitte geben Sie der Kamera die notwendige Berechtigung': 'Prosimy o udzielenie kamerze niezbędnych uprawnień',
    'Barcode scannen': 'Skanuj kod kreskowy',
    'Scanner Error': 'Błąd skanowania',
    'QR-Code scannen um den Digitalen Beleg zu erhalten': 'Zeskanuj kod QR, aby otrzymać cyfrowy paragon',
    'Druckwarteschlange leeren': 'Pusta kolejka wydruku',
  },
}
// !generated by script
