import { localI18n } from '~src/i18n.ts'
import { appTranslations } from '~src/globals/i18n/appTranslations.ts'
import { isApp } from '~src/appWebView.ts'
import { appData } from '~src/globals/variables.ts'

export const getLocales = () => {
  const { t } = localI18n(i18nMessages)

  return {
    app_translations: appTranslations(),
    error_input: t('Bitte Eingabe überprüfen'),
    brutto: t('Brutto'),
    discount: t('Rabatt'),
    amount: t('Anzahl'),
    ust: t('USt.'),
    price: t('Preis'),
    price_hint: t('Preis * Anzahl'),
    price_hint_absolute: t('Preis * Anzahl - Rabatt in') + ' ' + appData.custom.company?.currency,
    price_hint_percent: t('(Preis - Rabatt in %) * Anzahl'),
    article_category_home: t('Artikel'),
    service_category_home: t('Dienstleistungen'),
    choose_article_category: t('Um Artikel sortieren zu können, wählen Sie bitte zuvor eine Kategorie.'),
    choose_service_category: t('Um Dienstleistungen sortieren zu können, wählen Sie bitte zuvor eine Kategorie.'),
    choose_customer: t('Kunde wählen'),
    rebook_to: t('Umbuchen nach'),
    no_table_found: t('Kein Tisch gefunden'),
    set_area_first: t('Legen Sie zuerst einen Bereich an.'),
    import_empty: t('Die angegebene Datei ist leer.'),
    import_size_2: t('Die Dateigröße darf maximal 2MB betragen.'),
    import_size_5: t('Die Dateigröße darf maximal 5MB betragen.'),
    import_type: t('Bitte wählen Sie eine CSV Datei.'),
    import_type_zip: t('Bitte wählen Sie eine ZIP Datei.'),
    uid: t('UID'),
    taxNumber: t('Steuernummer'),
    companyNumber: t('Firmenbuch Nr.'),
    tel: t('Tel.'),
    email: t('Email'),
    web: t('Web'),
    iban: t('IBAN'),
    bic: t('BIC'),
    copy: t('KOPIE'),
    cancellation: t('Storno'),
    cancelled: t('Storniert'),
    date: t('Datum'),
    location: t('Ort'),
    cashier: t('Kassier'),
    employee: t('Mitarbeiter'),
    signature: t('Signatur'),
    signature2: t('Unterschrift'),
    cashRegister: t('Kasse'),
    createdBy: t('Erstellt von'),
    at: t('Am'),
    thank_you: t('Vielen Dank'),
    sale_label: t('Umsatz'),
    sold_label: t('Verkaufszahlen'),
    week: t('Woche'),
    not_an_image: t('Ungültiges Format, nur jpg, png und gif ist erlaubt.'),
    active: t('Aktivieren'),
    inactive: t('Deaktivieren'),
    commissioned: t('Beauftragt'),
    no: t('Keine'),
    none: t('Keiner'),
    none2: t('Keines'),
    choose: t('Wählen'),
    sort: t('Sortieren'),
    save: t('Speichern'),
    max_positions_reached: t('Maximale Anzahl an Positionen erreicht.'),
    signed: t('Unterzeichnet'),
    email_addresses: t('E-Mail-Adressen'),
    sequence_number: t('Sequenznummer'),
    generic_error: t('Es ist ein Fehler aufgetreten'),
    error_try_again: t('Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.'),
    gastro_back_btn: t('Zurück'),
    gastro_add_btn: t('Hinzufügen'),
    gastro_send_btn: t('Absenden'),
    table: t('Tisch'),
    table_number: t('Tisch Nr.'),
    group: t('Gruppe'),
    order_overview: t('Bestellübersicht Nr.'),
    order_overview_info: t('Dies ist kein Beleg / Rechnung'),
    print_success: t('Druckauftrag erfolgreich abgesendet.'),
    print_failure: t('Druckauftrag konnte nicht gesendet werden.'),
    printer_queue_failure: t('Die Bestellung befindet sich in der Warteschlange.'),
    printer_queue_failure_count: t('insgesamt'),
    printer_not_found: t('Kein Drucker gefunden'),
    invalid_voucher_format: t('Ungültiger Gutscheincode'),
    duplicate_voucher_input: t('Gutscheincode wurde bereits eingegeben'),
    select_all: t('Alle auswählen'),
    deselect_all: t('Alle abwählen'),
    no_receivers: t('Keine Empfänger ausgewählt'),
    number_of_receivers: t('%s Empfänger ausgewählt'),
    number_of_checked_articles: t('%x von %y Artikel ausgewählt.'),
    select_all_articles: t('Alle Artikel auswählen?'),
    number_of_checked_services: t('%x von %y Dienstleistungen ausgewählt.'),
    select_all_services: t('Alle Dienstleistungen auswählen?'),
    number_of_checked_entries: t('%x von %y Einträgen ausgewählt.'),
    select_all_entries: t('Alle Einträge auswählen?'),
    email_includes_umlaut: t('Nach dem @-Zeichen darf kein Umlaut verwendet werden.'),
    offer_number: t('Angebot Nr.'),
    delivery_note_number: t('Lieferschein Nr.'),
    tse_tax_uid_number_error: t('Es muss entweder die Steuernummer oder die UID-Nummer angegeben werden.'),
    card_terminal_add_invoice_failed: t(
      'Bei der Erstellung der Rechnung ist ein Fehler aufgetreten. Bitte überprüfen Sie im Dashboard ihres Kartenterminals ihre letzte Überweisung.',
    ),
    keep: t('Beibehalten'),
    invoice_customer: t('Rechnungsempfänger'),
    hospitality_expenses: 'Bewirtungsaufwand-Angaben (Par.4 Abs.5 Ziff 2 Estg)',
    hospitality_people: t('Bewirtete Person(en)'),
    hospitality_reason: t('Anlass der Bewirtung'),
    hospitality_amount: t('Höhe der Aufwendungen'),
    hospitality_restaurant: t('Bei Bewirtung im Restaurant'),
    hospitality_others: t('in anderen Fällen'),
    no_results_text: t('Keine Ergebnisse gefunden'),
    search_close: t('Suche schließen'),
    columns: t('Spalten'),
    tendered: t('Gegeben Bar'),
    change: t('Retourgeld Bar'),
    wrong_credit_card_number: t('Ungültige Kartennummer. Bitte geben Sie 13-16 Ziffern ein.'),
    wrong_credit_card_data: t('Ungültige Karteninformationen. Bitte prüfen Sie Ihre Eingaben.'),
    wrong_cvc: t('Ungültige Prüfziffer. Mindestens 3 Ziffern erforderlich.'),
    wrong_expire_date: t('Ungültiges Ablaufdatum. Bitte prüfen Sie Ihre Eingaben.'),
    wrong_iban: t('IBAN ungültig. Bitte prüfen Sie Ihre Eingaben.'),
    tip_upgrade_modal_additional_text: t(
      'Die Trinkgeldfunktion kann in den %s ausgeblendet werden. (Achtung: bei Klick auf den Link verlässt man die Zahlungsseite)',
    ).replace(
      '%s',
      `<a style="text-decoration: underline; color: inherit;" href="/intern/cash-register/settings#tab-payment-methods"${isApp() ? '' : ' target="_blank"'}><strong>${t('Einstellungen')}</strong></a>`,
    ),
    invoice_cancellation: t('Rechnung stornieren'),
    this_invoice_cancellation: t('Diese Rechnung stornieren?'),
    invoice_restore: t('Rechnung wiederherstellen'),
    this_invoice_restore: t('Diese Rechnung wiederherstellen?'),
    invoice_restore_additional_gastro: t('Nach Wiederherstellung werden Sie zum Tisch weitergeleitet.'),
    invoice_restore_additional: t('Nach Wiederherstellung werden Sie zur Rechnungserstellung weitergeleitet.'),
    corrective_invoice: t('Korrekturrechnung'),
    currentSession: t('derzeitige Sitzung'),
    network_error_page_reload: t('Netzwerkfehler: Bitte aktualisieren Sie die Seite.'),
    network_error_retry: t('Netzwerkfehler: Bitte versuchen Sie es erneut.'),
    server_error_retry: t('Serverfehler: Bitte versuchen Sie es in einigen Momenten erneut.'),
    check_voucher_code: t('Bitte geben Sie einen gültigen Gutscheincode ein und prüfen sie diesen vor dem einlösen.'),
    use_take_away_prices: t('Zum-Mitnehmen-Preise werden angewendet'),
    use_in_house_prices: t('In-Haus-Preise werden angewendet'),
    export_timeout: t('Export-Zeitüberschreitung. Bitte den Zeitraum und die Exportoptionen reduzieren.'),
  }
}

// generated by script
export const i18nMessages = {
  'de-AT': {
    'Es ist ein Fehler aufgetreten': 'Es ist ein Fehler aufgetreten',
    Gruppe: 'Gruppe',
    Unterschrift: 'Unterschrift',
    Umsatz: 'Umsatz',
    'Ungültiger Gutscheincode': 'Ungültiger Gutscheincode',
    Rabatt: 'Rabatt',
    'Die Dateigröße darf maximal 2MB betragen.': 'Die Dateigröße darf maximal 2MB betragen.',
    Zurück: 'Zurück',
    'USt.': 'USt.',
    Artikel: 'Artikel',
    UID: 'UID',
    Brutto: 'Brutto',
    Mitarbeiter: 'Mitarbeiter',
    Datum: 'Datum',
    Storniert: 'Storniert',
    'Lieferschein Nr.': 'Lieferschein Nr.',
    'Tel.': 'Tel.',
    Web: 'Web',
    IBAN: 'IBAN',
    BIC: 'BIC',
    'Firmenbuch Nr.': 'Firmenbuch Nr.',
    'Rechnung stornieren': 'Rechnung stornieren',
    Kassier: 'Kassier',
    'Erstellt von': 'Erstellt von',
    Storno: 'Storno',
    Sequenznummer: 'Sequenznummer',
    Signatur: 'Signatur',
    Anzahl: 'Anzahl',
    'Angebot Nr.': 'Angebot Nr.',
    Dienstleistungen: 'Dienstleistungen',
    Einstellungen: 'Einstellungen',
    'Es muss entweder die Steuernummer oder die UID-Nummer angegeben werden.':
      'Es muss entweder die Steuernummer oder die UID-Nummer angegeben werden.',
    'Die angegebene Datei ist leer.': 'Die angegebene Datei ist leer.',
    'Die Dateigröße darf maximal 5MB betragen.': 'Die Dateigröße darf maximal 5MB betragen.',
    'Bitte wählen Sie eine CSV Datei.': 'Bitte wählen Sie eine CSV Datei.',
    Am: 'Am',
    Kasse: 'Kasse',
    Tisch: 'Tisch',
    Preis: 'Preis',
    KOPIE: 'KOPIE',
    'Bitte Eingabe überprüfen': 'Bitte Eingabe überprüfen',
    'Preis * Anzahl': 'Preis * Anzahl',
    'Preis * Anzahl - Rabatt in': 'Preis * Anzahl - Rabatt in',
    '(Preis - Rabatt in %) * Anzahl': '(Preis - Rabatt in %) * Anzahl',
    'Um Artikel sortieren zu können, wählen Sie bitte zuvor eine Kategorie.':
      'Um Artikel sortieren zu können, wählen Sie bitte zuvor eine Kategorie.',
    'Um Dienstleistungen sortieren zu können, wählen Sie bitte zuvor eine Kategorie.':
      'Um Dienstleistungen sortieren zu können, wählen Sie bitte zuvor eine Kategorie.',
    'Kunde wählen': 'Kunde wählen',
    'Umbuchen nach': 'Umbuchen nach',
    'Kein Tisch gefunden': 'Kein Tisch gefunden',
    'Legen Sie zuerst einen Bereich an.': 'Legen Sie zuerst einen Bereich an.',
    Email: 'Email',
    'Vielen Dank': 'Vielen Dank',
    Verkaufszahlen: 'Verkaufszahlen',
    Woche: 'Woche',
    'Ungültiges Format, nur jpg, png und gif ist erlaubt.': 'Ungültiges Format, nur jpg, png und gif ist erlaubt.',
    Aktivieren: 'Aktivieren',
    Deaktivieren: 'Deaktivieren',
    Beauftragt: 'Beauftragt',
    Keine: 'Keine',
    Keiner: 'Keiner',
    Keines: 'Keines',
    Wählen: 'Wählen',
    Sortieren: 'Sortieren',
    Speichern: 'Speichern',
    'Maximale Anzahl an Positionen erreicht.': 'Maximale Anzahl an Positionen erreicht.',
    Unterzeichnet: 'Unterzeichnet',
    'E-Mail-Adressen': 'E-Mail-Adressen',
    Hinzufügen: 'Hinzufügen',
    Absenden: 'Absenden',
    'Druckauftrag erfolgreich abgesendet.': 'Druckauftrag erfolgreich abgesendet.',
    'Druckauftrag konnte nicht gesendet werden.': 'Druckauftrag konnte nicht gesendet werden.',
    'Gutscheincode wurde bereits eingegeben': 'Gutscheincode wurde bereits eingegeben',
    'Alle auswählen': 'Alle auswählen',
    'Alle abwählen': 'Alle abwählen',
    'Keine Empfänger ausgewählt': 'Keine Empfänger ausgewählt',
    '%s Empfänger ausgewählt': '%s Empfänger ausgewählt',
    '%x von %y Artikel ausgewählt.': '%x von %y Artikel ausgewählt.',
    'Alle Artikel auswählen?': 'Alle Artikel auswählen?',
    '%x von %y Dienstleistungen ausgewählt.': '%x von %y Dienstleistungen ausgewählt.',
    'Alle Dienstleistungen auswählen?': 'Alle Dienstleistungen auswählen?',
    '%x von %y Einträgen ausgewählt.': '%x von %y Einträgen ausgewählt.',
    'Alle Einträge auswählen?': 'Alle Einträge auswählen?',
    'Nach dem @-Zeichen darf kein Umlaut verwendet werden.':
      "Nach dem {'@'}-Zeichen darf kein Umlaut verwendet werden.",
    'Bei der Erstellung der Rechnung ist ein Fehler aufgetreten. Bitte überprüfen Sie im Dashboard ihres Kartenterminals ihre letzte Überweisung.':
      'Bei der Erstellung der Rechnung ist ein Fehler aufgetreten. Bitte überprüfen Sie im Dashboard ihres Kartenterminals ihre letzte Überweisung.',
    Steuernummer: 'Steuernummer',
    'Diese Rechnung stornieren?': 'Diese Rechnung stornieren?',
    'Tisch Nr.': 'Tisch Nr.',
    'Die Trinkgeldfunktion kann in den %s ausgeblendet werden. (Achtung: bei Klick auf den Link verlässt man die Zahlungsseite)':
      'Die Trinkgeldfunktion kann in den %s ausgeblendet werden. (Achtung: bei Klick auf den Link verlässt man die Zahlungsseite)',
    'Gegeben Bar': 'Gegeben Bar',
    'Retourgeld Bar': 'Retourgeld Bar',
    'Bewirtete Person(en)': 'Bewirtete Person(en)',
    'Anlass der Bewirtung': 'Anlass der Bewirtung',
    'Höhe der Aufwendungen': 'Höhe der Aufwendungen',
    'Bei Bewirtung im Restaurant': 'Bei Bewirtung im Restaurant',
    'in anderen Fällen': 'in anderen Fällen',
    Ort: 'Ort',
    Rechnungsempfänger: 'Rechnungsempfänger',
    'Bestellübersicht Nr.': 'Bestellübersicht Nr.',
    'Dies ist kein Beleg / Rechnung': 'Dies ist kein Beleg / Rechnung',
    'Bitte wählen Sie eine ZIP Datei.': 'Bitte wählen Sie eine ZIP Datei.',
    Beibehalten: 'Beibehalten',
    'Keine Ergebnisse gefunden': 'Keine Ergebnisse gefunden',
    'Suche schließen': 'Suche schließen',
    Spalten: 'Spalten',
    'Ungültige Kartennummer. Bitte geben Sie 13-16 Ziffern ein.':
      'Ungültige Kartennummer. Bitte geben Sie 13-16 Ziffern ein.',
    'Ungültige Karteninformationen. Bitte prüfen Sie Ihre Eingaben.':
      'Ungültige Karteninformationen. Bitte prüfen Sie Ihre Eingaben.',
    'Ungültige Prüfziffer. Mindestens 3 Ziffern erforderlich.':
      'Ungültige Prüfziffer. Mindestens 3 Ziffern erforderlich.',
    'Ungültiges Ablaufdatum. Bitte prüfen Sie Ihre Eingaben.':
      'Ungültiges Ablaufdatum. Bitte prüfen Sie Ihre Eingaben.',
    'IBAN ungültig. Bitte prüfen Sie Ihre Eingaben.': 'IBAN ungültig. Bitte prüfen Sie Ihre Eingaben.',
    'Rechnung wiederherstellen': 'Rechnung wiederherstellen',
    'Diese Rechnung wiederherstellen?': 'Diese Rechnung wiederherstellen?',
    Korrekturrechnung: 'Korrekturrechnung',
    'Nach Wiederherstellung werden Sie zum Tisch weitergeleitet.':
      'Nach Wiederherstellung werden Sie zum Tisch weitergeleitet.',
    'Nach Wiederherstellung werden Sie zur Rechnungserstellung weitergeleitet.':
      'Nach Wiederherstellung werden Sie zur Rechnungserstellung weitergeleitet.',
    'derzeitige Sitzung': 'derzeitige Sitzung',
    'Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.':
      'Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.',
    'Netzwerkfehler: Bitte aktualisieren Sie die Seite.': 'Netzwerkfehler: Bitte aktualisieren Sie die Seite.',
    'Netzwerkfehler: Bitte versuchen Sie es erneut.': 'Netzwerkfehler: Bitte versuchen Sie es erneut.',
    'Serverfehler: Bitte versuchen Sie es in einigen Momenten erneut.':
      'Serverfehler: Bitte versuchen Sie es in einigen Momenten erneut.',
    'Bitte geben Sie einen gültigen Gutscheincode ein und prüfen sie diesen vor dem einlösen.':
      'Bitte geben Sie einen gültigen Gutscheincode ein und prüfen sie diesen vor dem Einlösen.',
    'Zum-Mitnehmen-Preise werden angewendet': 'Mitnahme-Preise werden angewendet',
    'In-Haus-Preise werden angewendet': 'In-Haus-Preise werden angewendet',
    'Die Bestellung befindet sich in der Warteschlange.': 'Die Bestellung befindet sich in der Warteschlange.',
    'Kein Drucker gefunden': 'Kein Drucker gefunden',
    insgesamt: 'insgesamt',
    'Export-Zeitüberschreitung. Bitte den Zeitraum und die Exportoptionen reduzieren.':
      'Export-Zeitüberschreitung. Bitte den Zeitraum und die Exportoptionen reduzieren.',
  },
  'de-DE': {
    'Es ist ein Fehler aufgetreten': 'Es ist ein Fehler aufgetreten',
    Gruppe: 'Gruppe',
    Unterschrift: 'Unterschrift',
    Umsatz: 'Umsatz',
    'Ungültiger Gutscheincode': 'Ungültiger Gutscheincode',
    Rabatt: 'Rabatt',
    'Die Dateigröße darf maximal 2MB betragen.': 'Die Dateigröße darf maximal 2MB betragen.',
    Zurück: 'Zurück',
    'USt.': 'USt.',
    Artikel: 'Artikel',
    UID: 'USt-ID',
    Brutto: 'Brutto',
    Mitarbeiter: 'Mitarbeiter',
    Datum: 'Datum',
    Storniert: 'Storniert',
    'Lieferschein Nr.': 'Lieferschein Nr.',
    'Tel.': 'Tel.',
    Web: 'Web',
    IBAN: 'IBAN',
    BIC: 'BIC',
    'Firmenbuch Nr.': 'Handelsregister Nr.',
    'Rechnung stornieren': 'Rechnung stornieren',
    Kassier: 'KassiererIn',
    'Erstellt von': 'Erstellt von',
    Storno: 'Storno',
    Sequenznummer: 'Sequenznummer',
    Signatur: 'Signatur',
    Anzahl: 'Anzahl',
    'Angebot Nr.': 'Angebot Nr.',
    Dienstleistungen: 'Dienstleistungen',
    Einstellungen: 'Einstellungen',
    'Es muss entweder die Steuernummer oder die UID-Nummer angegeben werden.':
      'Es muss entweder die Steuernummer oder die USt-ID angegeben werden.',
    'Die angegebene Datei ist leer.': 'Die angegebene Datei ist leer.',
    'Die Dateigröße darf maximal 5MB betragen.': 'Die Dateigröße darf maximal 5MB betragen.',
    'Bitte wählen Sie eine CSV Datei.': 'Bitte wählen Sie eine CSV Datei.',
    Am: 'Am',
    Kasse: 'Kasse',
    Tisch: 'Tisch',
    Preis: 'Preis',
    KOPIE: 'KOPIE',
    'Bitte Eingabe überprüfen': 'Bitte Eingabe überprüfen',
    'Preis * Anzahl': 'Preis * Anzahl',
    'Preis * Anzahl - Rabatt in': 'Preis * Anzahl - Rabatt in',
    '(Preis - Rabatt in %) * Anzahl': '(Preis - Rabatt in %) * Anzahl',
    'Um Artikel sortieren zu können, wählen Sie bitte zuvor eine Kategorie.':
      'Um Artikel sortieren zu können, wählen Sie bitte zuvor eine Kategorie.',
    'Um Dienstleistungen sortieren zu können, wählen Sie bitte zuvor eine Kategorie.':
      'Um Dienstleistungen sortieren zu können, wählen Sie bitte zuvor eine Kategorie.',
    'Kunde wählen': 'Kunde wählen',
    'Umbuchen nach': 'Umbuchen nach',
    'Kein Tisch gefunden': 'Kein Tisch gefunden',
    'Legen Sie zuerst einen Bereich an.': 'Legen Sie zuerst einen Bereich an.',
    Email: 'Email',
    'Vielen Dank': 'Vielen Dank',
    Verkaufszahlen: 'Verkaufszahlen',
    Woche: 'Woche',
    'Ungültiges Format, nur jpg, png und gif ist erlaubt.': 'Ungültiges Format, nur jpg, png und gif ist erlaubt.',
    Aktivieren: 'Aktivieren',
    Deaktivieren: 'Deaktivieren',
    Beauftragt: 'Beauftragt',
    Keine: 'Keine',
    Keiner: 'Keiner',
    Keines: 'Keines',
    Wählen: 'Wählen',
    Sortieren: 'Sortieren',
    Speichern: 'Speichern',
    'Maximale Anzahl an Positionen erreicht.': 'Maximale Anzahl an Positionen erreicht.',
    Unterzeichnet: 'Unterzeichnet',
    'E-Mail-Adressen': 'E-Mail-Adressen',
    Hinzufügen: 'Hinzufügen',
    Absenden: 'Absenden',
    'Druckauftrag erfolgreich abgesendet.': 'Druckauftrag erfolgreich abgesendet.',
    'Druckauftrag konnte nicht gesendet werden.': 'Druckauftrag konnte nicht gesendet werden.',
    'Gutscheincode wurde bereits eingegeben': 'Gutscheincode wurde bereits eingegeben',
    'Alle auswählen': 'Alle auswählen',
    'Alle abwählen': 'Alle abwählen',
    'Keine Empfänger ausgewählt': 'Keine Empfänger ausgewählt',
    '%s Empfänger ausgewählt': '%s Empfänger ausgewählt',
    '%x von %y Artikel ausgewählt.': '%x von %y Artikel ausgewählt.',
    'Alle Artikel auswählen?': 'Alle Artikel auswählen?',
    '%x von %y Dienstleistungen ausgewählt.': '%x von %y Dienstleistungen ausgewählt.',
    'Alle Dienstleistungen auswählen?': 'Alle Dienstleistungen auswählen?',
    '%x von %y Einträgen ausgewählt.': '%x von %y Einträgen ausgewählt.',
    'Alle Einträge auswählen?': 'Alle Einträge auswählen?',
    'Nach dem @-Zeichen darf kein Umlaut verwendet werden.':
      "Nach dem {'@'}-Zeichen darf kein Umlaut verwendet werden.",
    'Bei der Erstellung der Rechnung ist ein Fehler aufgetreten. Bitte überprüfen Sie im Dashboard ihres Kartenterminals ihre letzte Überweisung.':
      'Bei der Erstellung der Rechnung ist ein Fehler aufgetreten. Bitte überprüfen Sie im Dashboard ihres Kartenterminals ihre letzte Überweisung.',
    Steuernummer: 'Steuernummer',
    'Diese Rechnung stornieren?': 'Diese Rechnung stornieren?',
    'Tisch Nr.': 'Tisch Nr.',
    'Die Trinkgeldfunktion kann in den %s ausgeblendet werden. (Achtung: bei Klick auf den Link verlässt man die Zahlungsseite)':
      'Die Trinkgeldfunktion kann in den %s ausgeblendet werden. (Achtung: bei Klick auf den Link verlässt man die Zahlungsseite)',
    'Gegeben Bar': 'Gegeben Bar',
    'Retourgeld Bar': 'Rückgeld Bar',
    'Bewirtete Person(en)': 'Bewirtete Person(en)',
    'Anlass der Bewirtung': 'Anlass der Bewirtung',
    'Höhe der Aufwendungen': 'Höhe der Aufwendungen',
    'Bei Bewirtung im Restaurant': 'Bei Bewirtung im Restaurant',
    'in anderen Fällen': 'in anderen Fällen',
    Ort: 'Ort',
    Rechnungsempfänger: 'Rechnungsempfänger',
    'Bestellübersicht Nr.': 'Bestellübersicht Nr.',
    'Dies ist kein Beleg / Rechnung': 'Dies ist kein Beleg / Rechnung',
    'Bitte wählen Sie eine ZIP Datei.': 'Bitte wählen Sie eine ZIP Datei.',
    Beibehalten: 'Beibehalten',
    'Keine Ergebnisse gefunden': 'Keine Ergebnisse gefunden',
    'Suche schließen': 'Suche schließen',
    Spalten: 'Spalten',
    'Ungültige Kartennummer. Bitte geben Sie 13-16 Ziffern ein.':
      'Ungültige Kartennummer. Bitte geben Sie 13-16 Ziffern ein.',
    'Ungültige Karteninformationen. Bitte prüfen Sie Ihre Eingaben.':
      'Ungültige Karteninformationen. Bitte prüfen Sie Ihre Eingaben.',
    'Ungültige Prüfziffer. Mindestens 3 Ziffern erforderlich.':
      'Ungültige Prüfziffer. Mindestens 3 Ziffern erforderlich.',
    'Ungültiges Ablaufdatum. Bitte prüfen Sie Ihre Eingaben.':
      'Ungültiges Ablaufdatum. Bitte prüfen Sie Ihre Eingaben.',
    'IBAN ungültig. Bitte prüfen Sie Ihre Eingaben.': 'IBAN ungültig. Bitte prüfen Sie Ihre Eingaben.',
    'Rechnung wiederherstellen': 'Rechnung wiederherstellen',
    'Diese Rechnung wiederherstellen?': 'Diese Rechnung wiederherstellen?',
    Korrekturrechnung: 'Korrekturrechnung',
    'Nach Wiederherstellung werden Sie zum Tisch weitergeleitet.':
      'Nach Wiederherstellung werden Sie zum Tisch weitergeleitet.',
    'Nach Wiederherstellung werden Sie zur Rechnungserstellung weitergeleitet.':
      'Nach Wiederherstellung werden Sie zur Rechnungserstellung weitergeleitet.',
    'derzeitige Sitzung': 'derzeitige Sitzung',
    'Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.':
      'Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.',
    'Netzwerkfehler: Bitte aktualisieren Sie die Seite.': 'Netzwerkfehler: Bitte aktualisieren Sie die Seite.',
    'Netzwerkfehler: Bitte versuchen Sie es erneut.': 'Netzwerkfehler: Bitte versuchen Sie es erneut.',
    'Serverfehler: Bitte versuchen Sie es in einigen Momenten erneut.':
      'Serverfehler: Bitte versuchen Sie es in einigen Momenten erneut.',
    'Bitte geben Sie einen gültigen Gutscheincode ein und prüfen sie diesen vor dem einlösen.':
      'Bitte geben Sie einen gültigen Gutscheincode ein und prüfen sie diesen vor dem Einlösen.',
    'Zum-Mitnehmen-Preise werden angewendet': 'Außer-Haus-Preise werden angewendet',
    'In-Haus-Preise werden angewendet': 'In-Haus-Preise werden angewendet',
    'Die Bestellung befindet sich in der Warteschlange.': 'Die Bestellung befindet sich in der Warteschlange.',
    'Kein Drucker gefunden': 'Kein Drucker gefunden',
    insgesamt: 'insgesamt',
    'Export-Zeitüberschreitung. Bitte den Zeitraum und die Exportoptionen reduzieren.':
      'Export-Zeitüberschreitung. Bitte den Zeitraum und die Exportoptionen reduzieren.',
  },
  'cs-CZ': {
    'Es ist ein Fehler aufgetreten': 'Vyskytla se chyba',
    Gruppe: 'Skupina',
    Unterschrift: 'Podpis',
    Umsatz: 'Tržba',
    'Ungültiger Gutscheincode': 'Neplatný kód kupónu',
    Rabatt: 'Sleva',
    'Die Dateigröße darf maximal 2MB betragen.': 'Velikost souboru může být maximálně 2 MB.',
    Zurück: 'Zpět',
    'USt.': 'DPH.',
    Artikel: 'Zboží',
    UID: 'DIČ',
    Brutto: 'S DPH',
    Mitarbeiter: 'Zaměstnanec',
    Datum: 'Datum',
    Storniert: 'Stornováno',
    'Lieferschein Nr.': 'Dodací list č.',
    'Tel.': 'Tel.',
    Web: 'Web',
    IBAN: 'IBAN',
    BIC: 'BIC',
    'Firmenbuch Nr.': 'IČO.',
    'Rechnung stornieren': 'Stornovat doklad',
    Kassier: 'Pokladní',
    'Erstellt von': 'Vytvořil',
    Storno: 'Storno',
    Sequenznummer: 'DIČ',
    Signatur: 'EET',
    Anzahl: 'Počet',
    'Angebot Nr.': 'Nabídka č.',
    Dienstleistungen: 'Služby',
    Einstellungen: 'Nastavení',
    'Es muss entweder die Steuernummer oder die UID-Nummer angegeben werden.':
      'Je třeba uvést daňové číslo nebo číslo DIČ.',
    'Die angegebene Datei ist leer.': 'Uvedený soubor je prázdný.',
    'Die Dateigröße darf maximal 5MB betragen.': 'Velikost souboru může být maximálně 5 MB.',
    'Bitte wählen Sie eine CSV Datei.': 'Vyberte prosím soubor CSV.',
    Am: 'Dne',
    Kasse: 'Pokladna',
    Tisch: 'Stůl',
    Preis: 'Cena',
    KOPIE: 'KOPIE',
    'Bitte Eingabe überprüfen': 'Zkontrolujte zadání',
    'Preis * Anzahl': 'Cena * Počet',
    'Preis * Anzahl - Rabatt in': 'Cena * Počet - Sleva v',
    '(Preis - Rabatt in %) * Anzahl': '(Cena - Sleva v %) * Počet',
    'Um Artikel sortieren zu können, wählen Sie bitte zuvor eine Kategorie.':
      'Abyste mohli seřadit zboží, vyberte nejprve kategorii.',
    'Um Dienstleistungen sortieren zu können, wählen Sie bitte zuvor eine Kategorie.':
      'Abyste mohli seřadit služby, vyberte nejprve kategorii.',
    'Kunde wählen': 'Vybrat zákazníka',
    'Umbuchen nach': 'Převést objednávku na',
    'Kein Tisch gefunden': 'Nenalezen žádný stůl',
    'Legen Sie zuerst einen Bereich an.': 'Zadejte nejprve oblast.',
    Email: 'E-mail',
    'Vielen Dank': 'Děkujeme vám',
    Verkaufszahlen: 'Prodané množství',
    Woche: 'Týden',
    'Ungültiges Format, nur jpg, png und gif ist erlaubt.':
      'Neplatný formát souboru. Podporovány jsou pouze formáty JPG, PNG a GIF.',
    Aktivieren: 'Aktivovat',
    Deaktivieren: 'Deaktivovat',
    Beauftragt: 'Pověřený',
    Keine: 'Žádná',
    Keiner: 'Žádný',
    Keines: 'Žádná',
    Wählen: 'Vybrat',
    Sortieren: 'Seřadit',
    Speichern: 'Uložit',
    'Maximale Anzahl an Positionen erreicht.': 'Maximální počet dosažených pozic.',
    Unterzeichnet: 'Podepsáno',
    'E-Mail-Adressen': 'E-mailové adresy',
    Hinzufügen: 'Přidat',
    Absenden: 'Odeslat',
    'Druckauftrag erfolgreich abgesendet.': 'Tisková úloha byla úspěšně odeslána.',
    'Druckauftrag konnte nicht gesendet werden.': 'Nelze odeslat tiskovou úlohu.',
    'Gutscheincode wurde bereits eingegeben': 'Kód poukazu byl již zadán',
    'Alle auswählen': 'Vybrat všechny',
    'Alle abwählen': 'Odebrat všechny',
    'Keine Empfänger ausgewählt': 'Nebyl vybrán žádný příjemce',
    '%s Empfänger ausgewählt': 'Byl vybrán %s příjemce',
    '%x von %y Artikel ausgewählt.': 'Vybráno %x z %y položek.',
    'Alle Artikel auswählen?': 'Vybrat všechny položky?',
    '%x von %y Dienstleistungen ausgewählt.': 'Bylo vybráno %x z %y služeb.',
    'Alle Dienstleistungen auswählen?': 'Vybrat všechny služby?',
    '%x von %y Einträgen ausgewählt.': 'Bylo vybráno %x z %y záznamů.',
    'Alle Einträge auswählen?': 'Vybrat všechny záznamy?',
    'Nach dem @-Zeichen darf kein Umlaut verwendet werden.': "Po symbolu {'@'} nesmí být použita přehláska.",
    'Bei der Erstellung der Rechnung ist ein Fehler aufgetreten. Bitte überprüfen Sie im Dashboard ihres Kartenterminals ihre letzte Überweisung.':
      'Při vytváření faktury došlo k chybě. Zkontrolujte prosím svůj poslední převod na ovládacím panelu kartového terminálu.',
    Steuernummer: 'DIČ',
    'Diese Rechnung stornieren?': 'Stornovat tento doklad?',
    'Tisch Nr.': 'Stůl č.',
    'Die Trinkgeldfunktion kann in den %s ausgeblendet werden. (Achtung: bei Klick auf den Link verlässt man die Zahlungsseite)':
      'Funkce tipu může být skryta v %s. (Pozor: kliknutím na odkaz opustíte platební stránku).',
    'Gegeben Bar': 'Vydáno v hotovosti',
    'Retourgeld Bar': 'Vrácení peněz v hotovosti',
    'Bewirtete Person(en)': 'Obsluhovaná osoba (osoby)',
    'Anlass der Bewirtung': 'Příležitost k pohoštění',
    'Höhe der Aufwendungen': 'Výše výdajů',
    'Bei Bewirtung im Restaurant': 'Při obsloužení v restauraci',
    'in anderen Fällen': 'v jiných případech',
    Ort: 'Místo',
    Rechnungsempfänger: 'Příjemce faktury',
    'Bestellübersicht Nr.': 'Č. přehledu objednávky',
    'Dies ist kein Beleg / Rechnung': 'Toto není účtenka / faktura',
    'Bitte wählen Sie eine ZIP Datei.': 'Vyberte prosím soubor ZIP.',
    Beibehalten: 'Ponechat',
    'Keine Ergebnisse gefunden': 'Nebyly nalezeny žádné výsledky',
    'Suche schließen': 'Zavřít hledání',
    Spalten: 'Sloupce',
    'Ungültige Kartennummer. Bitte geben Sie 13-16 Ziffern ein.': 'Neplatné číslo karty. Zadejte prosím 13-16 číslic.',
    'Ungültige Karteninformationen. Bitte prüfen Sie Ihre Eingaben.':
      'Neplatné údaje o kartě. Zkontrolujte prosím své údaje.',
    'Ungültige Prüfziffer. Mindestens 3 Ziffern erforderlich.':
      'Neplatná kontrolní číslice. Jsou vyžadovány alespoň 3 číslice.',
    'Ungültiges Ablaufdatum. Bitte prüfen Sie Ihre Eingaben.':
      'Neplatné datum vypršení platnosti. Zkontrolujte prosím své údaje.',
    'IBAN ungültig. Bitte prüfen Sie Ihre Eingaben.': 'IBAN je neplatný. Zkontrolujte prosím své údaje.',
    'Rechnung wiederherstellen': 'Obnovit doklad',
    'Diese Rechnung wiederherstellen?': 'Obnovit tento doklad?',
    Korrekturrechnung: 'Opravný doklad',
    'Nach Wiederherstellung werden Sie zum Tisch weitergeleitet.': 'Po zotavení budete přesměrováni ke stolu.',
    'Nach Wiederherstellung werden Sie zur Rechnungserstellung weitergeleitet.':
      'Po obnovení budete přesměrováni na fakturaci.',
    'derzeitige Sitzung': 'současné setkání',
    'Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.': 'Došlo k chybě, zkuste to prosím znovu.',
    'Netzwerkfehler: Bitte aktualisieren Sie die Seite.': 'Chyba sítě: Aktualizujte prosím stránku.',
    'Netzwerkfehler: Bitte versuchen Sie es erneut.': 'Chyba sítě: Zkuste to prosím znovu.',
    'Serverfehler: Bitte versuchen Sie es in einigen Momenten erneut.':
      'Chyba serveru: Zkuste to prosím za několik okamžiků znovu.',
    'Bitte geben Sie einen gültigen Gutscheincode ein und prüfen sie diesen vor dem einlösen.':
      'Zadejte prosím platný kód kupónu a ověřte ho před jeho uplatněním.',
    'Zum-Mitnehmen-Preise werden angewendet': 'Platí ceny s sebou',
    'In-Haus-Preise werden angewendet': 'Platí interní ceny',
    'Die Bestellung befindet sich in der Warteschlange.': 'Objednávka je ve frontě.',
    'Kein Drucker gefunden': 'Nebyla nalezena žádná tiskárna',
    insgesamt: 'celkem',
    'Export-Zeitüberschreitung. Bitte den Zeitraum und die Exportoptionen reduzieren.':
      'Časový limit exportu vypršel. Zkraťte prosím období a snižte možnosti exportu.',
  },
  'fr-FR': {
    'Es ist ein Fehler aufgetreten': 'Une erreur est survenue',
    Gruppe: 'Groupe',
    Unterschrift: 'Signature',
    Umsatz: "Chiffre d'affaires",
    'Ungültiger Gutscheincode': 'Code de coupon invalide',
    Rabatt: 'Remise',
    'Die Dateigröße darf maximal 2MB betragen.': 'La taille maximale du fichier est de 2 Mo.',
    Zurück: 'Retour',
    'USt.': 'TVA.',
    Artikel: 'Articles',
    UID: 'TVA',
    Brutto: 'TTC',
    Mitarbeiter: 'Employés',
    Datum: 'Date',
    Storniert: 'Annulé',
    'Lieferschein Nr.': 'N° du bon de livraison.',
    'Tel.': 'Tél.',
    Web: 'Web',
    IBAN: 'IBAN',
    BIC: 'BIC',
    'Firmenbuch Nr.': 'Numéro RCS.',
    'Rechnung stornieren': 'Annuler le ticket',
    Kassier: 'Collaborateur·trice',
    'Erstellt von': 'Créé par',
    Storno: 'Annulation',
    Sequenznummer: 'TCP',
    Signatur: 'Signature',
    Anzahl: 'Quantité',
    'Angebot Nr.': 'Clôture jour. n°.',
    Dienstleistungen: 'Services',
    Einstellungen: 'Paramètres',
    'Es muss entweder die Steuernummer oder die UID-Nummer angegeben werden.':
      "Il est impératif d'indiquer soit le numéro fiscal, soit le numéro de TVA.",
    'Die angegebene Datei ist leer.': 'Le fichier indiqué est vide.',
    'Die Dateigröße darf maximal 5MB betragen.': 'La taille maximale du fichier est de 5 Mo.',
    'Bitte wählen Sie eine CSV Datei.': 'Veuillez choisir un fichier au format CSV.',
    Am: 'Le',
    Kasse: 'Caisse',
    Tisch: 'Table',
    Preis: 'Prix',
    KOPIE: 'DUPLICATA',
    'Bitte Eingabe überprüfen': 'Veuillez vérifier les informations que vous avez saisies',
    'Preis * Anzahl': 'Prix * Quantité',
    'Preis * Anzahl - Rabatt in': 'Prix * Quantité - Remise en',
    '(Preis - Rabatt in %) * Anzahl': '(Prix - remise en %) * nombre',
    'Um Artikel sortieren zu können, wählen Sie bitte zuvor eine Kategorie.':
      "Pour pouvoir trier les articles, choisissez d'abord une catégorie.",
    'Um Dienstleistungen sortieren zu können, wählen Sie bitte zuvor eine Kategorie.':
      "Pour pouvoir trier les services, choisissez d'abord une catégorie.",
    'Kunde wählen': 'Sélectionner un client',
    'Umbuchen nach': 'Modifier une réservation après',
    'Kein Tisch gefunden': 'Aucune table trouvée',
    'Legen Sie zuerst einen Bereich an.': "Créez d'abord un domaine.",
    Email: 'E-mail',
    'Vielen Dank': 'Un grand merci',
    Verkaufszahlen: 'Chiffres des ventes',
    Woche: 'Semaine',
    'Ungültiges Format, nur jpg, png und gif ist erlaubt.':
      'Format invalide, seuls les fichiers jpg, png et gif sont autorisés.',
    Aktivieren: 'Activer',
    Deaktivieren: 'Désactiver',
    Beauftragt: 'Montant',
    Keine: 'Aucun',
    Keiner: 'Aucun',
    Keines: 'Aucun(e)',
    Wählen: 'Sélectionner',
    Sortieren: 'Trier',
    Speichern: 'Enregistrer',
    'Maximale Anzahl an Positionen erreicht.': 'Nombre maximum de postes atteint.',
    Unterzeichnet: 'Signé',
    'E-Mail-Adressen': 'Adresses e-mail',
    Hinzufügen: 'Ajouter',
    Absenden: 'Envoi',
    'Druckauftrag erfolgreich abgesendet.': "Travail d'impression envoyé avec succès.",
    'Druckauftrag konnte nicht gesendet werden.': "Le travail d'impression n'a pas pu être envoyé.",
    'Gutscheincode wurde bereits eingegeben': 'Le code du bon a déjà été saisi',
    'Alle auswählen': 'Tout sélectionner',
    'Alle abwählen': 'Tout désélectionner',
    'Keine Empfänger ausgewählt': 'Aucun destinataire sélectionné',
    '%s Empfänger ausgewählt': '%s destinataire(s) sélectionné(s)',
    '%x von %y Artikel ausgewählt.': '%x article(s) sélectionné(s) sur %y.',
    'Alle Artikel auswählen?': 'Sélectionner tous les articles ?',
    '%x von %y Dienstleistungen ausgewählt.': '%x service(s) sélectionné(s) sur %y.',
    'Alle Dienstleistungen auswählen?': 'Sélectionner tous les services ?',
    '%x von %y Einträgen ausgewählt.': '%x entrée(s) sélectionnée(s) sur %y.',
    'Alle Einträge auswählen?': 'Sélectionner toutes les entrées ?',
    'Nach dem @-Zeichen darf kein Umlaut verwendet werden.':
      "Aucun umlaut ne peut être utilisé après le caractère {'@'}.",
    'Bei der Erstellung der Rechnung ist ein Fehler aufgetreten. Bitte überprüfen Sie im Dashboard ihres Kartenterminals ihre letzte Überweisung.':
      'Une erreur est survenue lors de la création de la facture. Veuillez vérifier votre dernier transfert dans le tableau de bord de votre terminal de carte.',
    Steuernummer: 'N° TVA intracommunautaire',
    'Diese Rechnung stornieren?': 'Annuler le ticket ?',
    'Tisch Nr.': 'Table n°',
    'Die Trinkgeldfunktion kann in den %s ausgeblendet werden. (Achtung: bei Klick auf den Link verlässt man die Zahlungsseite)':
      'La fonction pourboire peut être masquée au niveau suivant : %s. (Attention : en cliquant sur le lien, vous quitterez la page de paiement)',
    'Gegeben Bar': 'Espèces données',
    'Retourgeld Bar': 'Espèces rendues',
    'Bewirtete Person(en)': 'Personne(s) accueillie(s)',
    'Anlass der Bewirtung': "Motif de l'accueil",
    'Höhe der Aufwendungen': 'Montant des dépenses',
    'Bei Bewirtung im Restaurant': 'En cas de service au restaurant',
    'in anderen Fällen': "dans d'autres cas",
    Ort: 'Lieu',
    Rechnungsempfänger: 'Destinataire de la facture',
    'Bestellübersicht Nr.': 'Aperçu de la commande n°',
    'Dies ist kein Beleg / Rechnung': "Ceci n'est pas un reçu / une facture",
    'Bitte wählen Sie eine ZIP Datei.': 'Veuillez sélectionner un fichier ZIP.',
    Beibehalten: 'Conserver',
    'Keine Ergebnisse gefunden': "Aucun résultat n'a été trouvé",
    'Suche schließen': 'Fermer la recherche',
    Spalten: 'Colonnes',
    'Ungültige Kartennummer. Bitte geben Sie 13-16 Ziffern ein.':
      'Numéro de carte non valide. Veuillez saisir 13 à 16 chiffres.',
    'Ungültige Karteninformationen. Bitte prüfen Sie Ihre Eingaben.':
      'Informations relatives à la carte non valides. Veuillez vérifier les données saisies.',
    'Ungültige Prüfziffer. Mindestens 3 Ziffern erforderlich.':
      'Numéro de contrôle non valide. Au moins 3 chiffres sont requis.',
    'Ungültiges Ablaufdatum. Bitte prüfen Sie Ihre Eingaben.':
      "Date d'expiration non valide. Veuillez vérifier les données saisies.",
    'IBAN ungültig. Bitte prüfen Sie Ihre Eingaben.': 'IBAN non valide. Veuillez vérifier les données saisies.',
    'Rechnung wiederherstellen': 'Récupérer le ticket',
    'Diese Rechnung wiederherstellen?': 'Récupérer ce ticket ?',
    Korrekturrechnung: 'Ticket rectificatif',
    'Nach Wiederherstellung werden Sie zum Tisch weitergeleitet.':
      'Une fois la restauration terminée, vous serez redirigé(e) vers la table.',
    'Nach Wiederherstellung werden Sie zur Rechnungserstellung weitergeleitet.':
      'Une fois la restauration effectuée, vous serez redirigé(e) vers la facturation.',
    'derzeitige Sitzung': 'session actuelle',
    'Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.': "Une erreur s'est produite, veuillez réessayer.",
    'Netzwerkfehler: Bitte aktualisieren Sie die Seite.': 'Erreur réseau : Veuillez actualiser la page.',
    'Netzwerkfehler: Bitte versuchen Sie es erneut.': 'Erreur réseau : Veuillez réessayer.',
    'Serverfehler: Bitte versuchen Sie es in einigen Momenten erneut.':
      'Erreur de serveur : Veuillez réessayer dans quelques instants.',
    'Bitte geben Sie einen gültigen Gutscheincode ein und prüfen sie diesen vor dem einlösen.':
      "Veuillez entrer un code de bon valide et vérifiez-le avant de l'échanger.",
    'Zum-Mitnehmen-Preise werden angewendet': "Les prix à emporter s'appliquent",
    'In-Haus-Preise werden angewendet': "Le prix interne s'applique",
    'Die Bestellung befindet sich in der Warteschlange.': "La commande se trouve dans la file d'attente.",
    'Kein Drucker gefunden': 'Aucune imprimante trouvée',
    insgesamt: 'total',
    'Export-Zeitüberschreitung. Bitte den Zeitraum und die Exportoptionen reduzieren.':
      "Délai d'exportation dépassé. Veuillez réduire la période et les options d'exportation.",
  },
  'en-GB': {
    'Es ist ein Fehler aufgetreten': 'There was an error',
    Gruppe: 'Group',
    Unterschrift: 'Signature',
    Umsatz: 'Sales',
    'Ungültiger Gutscheincode': 'Invalid voucher code',
    Rabatt: 'Discount',
    'Die Dateigröße darf maximal 2MB betragen.': 'The file size must not exceed 2MB.',
    Zurück: 'Back',
    'USt.': 'VAT.',
    Artikel: 'Article',
    UID: 'VAT',
    Brutto: 'Gross',
    Mitarbeiter: 'Employee',
    Datum: 'Date',
    Storniert: 'Cancelled',
    'Lieferschein Nr.': 'Delivery note no.',
    'Tel.': 'Tel.',
    Web: 'Web',
    IBAN: 'IBAN',
    BIC: 'BIC',
    'Firmenbuch Nr.': 'Company register no.',
    'Rechnung stornieren': 'Cancel Invoice',
    Kassier: 'Cashier',
    'Erstellt von': 'Created by',
    Storno: 'Cancellation',
    Sequenznummer: 'Sequence number',
    Signatur: 'Signature',
    Anzahl: 'Quantity',
    'Angebot Nr.': 'Offer no.',
    Dienstleistungen: 'Services',
    Einstellungen: 'Settings',
    'Es muss entweder die Steuernummer oder die UID-Nummer angegeben werden.':
      'Either the tax number or the VAT number must be given.',
    'Die angegebene Datei ist leer.': 'The specified file is empty.',
    'Die Dateigröße darf maximal 5MB betragen.': 'The file size must not exceed 5MB.',
    'Bitte wählen Sie eine CSV Datei.': 'Please choose a CSV file.',
    Am: 'On',
    Kasse: 'Cash Register',
    Tisch: 'Table',
    Preis: 'Price',
    KOPIE: 'COPY',
    'Bitte Eingabe überprüfen': 'Please check your entry',
    'Preis * Anzahl': 'Price * Quantity',
    'Preis * Anzahl - Rabatt in': 'Price * Quantity - Discount in',
    '(Preis - Rabatt in %) * Anzahl': '(Price - Discount in %) * Quantity',
    'Um Artikel sortieren zu können, wählen Sie bitte zuvor eine Kategorie.':
      'To sort articles, please select a category beforehand.',
    'Um Dienstleistungen sortieren zu können, wählen Sie bitte zuvor eine Kategorie.':
      'To be able to sort services, please select a category beforehand.',
    'Kunde wählen': 'Select customer',
    'Umbuchen nach': 'Rebook to',
    'Kein Tisch gefunden': 'No table found',
    'Legen Sie zuerst einen Bereich an.': 'First select an area.',
    Email: 'Email',
    'Vielen Dank': 'Many Thanks',
    Verkaufszahlen: 'Sales figures',
    Woche: 'Week',
    'Ungültiges Format, nur jpg, png und gif ist erlaubt.': 'Invalid format, only jpg, png and gif is allowed.',
    Aktivieren: 'Activate',
    Deaktivieren: 'Deactivate',
    Beauftragt: 'Instructed',
    Keine: 'None',
    Keiner: 'None',
    Keines: 'None',
    Wählen: 'Choose',
    Sortieren: 'Sort',
    Speichern: 'Save',
    'Maximale Anzahl an Positionen erreicht.': 'Maximum number of positions reached.',
    Unterzeichnet: 'Signed',
    'E-Mail-Adressen': 'E-mail addresses',
    Hinzufügen: 'Add',
    Absenden: 'Send',
    'Druckauftrag erfolgreich abgesendet.': 'Print job sent successfully.',
    'Druckauftrag konnte nicht gesendet werden.': 'Print job could not be sent.',
    'Gutscheincode wurde bereits eingegeben': 'Voucher code has already been entered',
    'Alle auswählen': 'Select all',
    'Alle abwählen': 'Deselect all',
    'Keine Empfänger ausgewählt': 'No recipients selected',
    '%s Empfänger ausgewählt': '%s recipient selected',
    '%x von %y Artikel ausgewählt.': '%x of %y articles selected.',
    'Alle Artikel auswählen?': 'Select all articles?',
    '%x von %y Dienstleistungen ausgewählt.': '%x of %y services selected.',
    'Alle Dienstleistungen auswählen?': 'Select all services?',
    '%x von %y Einträgen ausgewählt.': '%x selected from %y entries.',
    'Alle Einträge auswählen?': 'Select all entries?',
    'Nach dem @-Zeichen darf kein Umlaut verwendet werden.': "Umlaut may not be used after the {'@'} sign.",
    'Bei der Erstellung der Rechnung ist ein Fehler aufgetreten. Bitte überprüfen Sie im Dashboard ihres Kartenterminals ihre letzte Überweisung.':
      'An error occurred while creating the invoice. Please check your last transfer in the dashboard of your card terminal.',
    Steuernummer: 'Tax Number',
    'Diese Rechnung stornieren?': 'Cancel Invoice?',
    'Tisch Nr.': 'Table no',
    'Die Trinkgeldfunktion kann in den %s ausgeblendet werden. (Achtung: bei Klick auf den Link verlässt man die Zahlungsseite)':
      'The tip function can be hidden in the %s. (Attention: if you click on the link you leave the payment page)',
    'Gegeben Bar': 'Given cash',
    'Retourgeld Bar': 'Return cash',
    'Bewirtete Person(en)': 'Hosted person(s)',
    'Anlass der Bewirtung': 'Occasion of the hospitality',
    'Höhe der Aufwendungen': 'Amount of expenditure',
    'Bei Bewirtung im Restaurant': 'When catering in the restaurant',
    'in anderen Fällen': 'in other cases',
    Ort: 'Location',
    Rechnungsempfänger: 'Invoice recipient',
    'Bestellübersicht Nr.': 'Order summary no.',
    'Dies ist kein Beleg / Rechnung': 'This is not a receipt / invoice',
    'Bitte wählen Sie eine ZIP Datei.': 'Please select a ZIP file.',
    Beibehalten: 'Keep',
    'Keine Ergebnisse gefunden': 'No results found',
    'Suche schließen': 'Close search',
    Spalten: 'Columns',
    'Ungültige Kartennummer. Bitte geben Sie 13-16 Ziffern ein.': 'Invalid card number. Please enter 13-16 digits.',
    'Ungültige Karteninformationen. Bitte prüfen Sie Ihre Eingaben.':
      'Invalid card information. Please check your entries.',
    'Ungültige Prüfziffer. Mindestens 3 Ziffern erforderlich.': 'Invalid checking number. At least 3 digits required.',
    'Ungültiges Ablaufdatum. Bitte prüfen Sie Ihre Eingaben.': 'Invalid expiration date. Please check your entries.',
    'IBAN ungültig. Bitte prüfen Sie Ihre Eingaben.': 'IBAN invalid. Please check your entries.',
    'Rechnung wiederherstellen': 'Recover invoice',
    'Diese Rechnung wiederherstellen?': 'Recover this invoice?',
    Korrekturrechnung: 'Corrective invoice',
    'Nach Wiederherstellung werden Sie zum Tisch weitergeleitet.': 'After recovery, you will be directed to the table.',
    'Nach Wiederherstellung werden Sie zur Rechnungserstellung weitergeleitet.':
      'After recovery, you will be redirected to invoicing.',
    'derzeitige Sitzung': 'current session',
    'Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.': 'An error has occurred, please try again.',
    'Netzwerkfehler: Bitte aktualisieren Sie die Seite.': 'Network error: Please refresh the page.',
    'Netzwerkfehler: Bitte versuchen Sie es erneut.': 'Network error: Please try again.',
    'Serverfehler: Bitte versuchen Sie es in einigen Momenten erneut.':
      'Server error: Please try again in a few moments.',
    'Bitte geben Sie einen gültigen Gutscheincode ein und prüfen sie diesen vor dem einlösen.':
      'Please enter a valid voucher code and check it before redeeming.',
    'Zum-Mitnehmen-Preise werden angewendet': 'Takeaway prices apply',
    'In-Haus-Preise werden angewendet': 'In-house pricing applies',
    'Die Bestellung befindet sich in der Warteschlange.': 'The order is in the queue.',
    'Kein Drucker gefunden': 'No printer found',
    insgesamt: 'in total',
    'Export-Zeitüberschreitung. Bitte den Zeitraum und die Exportoptionen reduzieren.':
      'Export timeout. Please reduce the period and export options.',
  },
  'es-ES': {
    'Es ist ein Fehler aufgetreten': 'Se ha producido un error',
    Gruppe: 'Grupo',
    Unterschrift: 'Firma',
    Umsatz: 'Facturación',
    'Ungültiger Gutscheincode': 'Código de cupón no válido',
    Rabatt: 'Descuento',
    'Die Dateigröße darf maximal 2MB betragen.': 'El tamaño del archivo no debe superar los 2 MB.',
    Zurück: 'Volver',
    'USt.': 'IVA',
    Artikel: 'Artículo',
    UID: 'NIF',
    Brutto: 'Bruto',
    Mitarbeiter: 'Empleados',
    Datum: 'Fecha',
    Storniert: 'Cancelado',
    'Lieferschein Nr.': 'Nº de comprobante de entrega.',
    'Tel.': 'Tel.',
    Web: 'Web',
    IBAN: 'IBAN',
    BIC: 'BIC',
    'Firmenbuch Nr.': 'Libro de compañía Nº.',
    'Rechnung stornieren': 'Cancelar factura',
    Kassier: 'Tesorero',
    'Erstellt von': 'Creado por',
    Storno: 'Cancelación',
    Sequenznummer: 'Número secuencial',
    Signatur: 'Firma',
    Anzahl: 'Cantidad',
    'Angebot Nr.': 'Oferta Nº.',
    Dienstleistungen: 'Servicios',
    Einstellungen: 'Ajustes',
    'Es muss entweder die Steuernummer oder die UID-Nummer angegeben werden.':
      'Debe indicarse el número de identificación fiscal.',
    'Die angegebene Datei ist leer.': 'El archivo especificado está vacío.',
    'Die Dateigröße darf maximal 5MB betragen.': 'El tamaño del archivo no debe superar 5 MB.',
    'Bitte wählen Sie eine CSV Datei.': 'Elija un archivo CSV.',
    Am: 'En',
    Kasse: 'Caja registradora',
    Tisch: 'Tabla',
    Preis: 'Precio',
    KOPIE: 'COPIA',
    'Bitte Eingabe überprüfen': 'Compruebe los datos',
    'Preis * Anzahl': 'Precio * Cantidad',
    'Preis * Anzahl - Rabatt in': 'Precio * Cantidad - Descuento en',
    '(Preis - Rabatt in %) * Anzahl': '(Precio - Descuento en %) * Cantidad',
    'Um Artikel sortieren zu können, wählen Sie bitte zuvor eine Kategorie.':
      'Para ordenar artículos, seleccione una categoría de antemano.',
    'Um Dienstleistungen sortieren zu können, wählen Sie bitte zuvor eine Kategorie.':
      'Para poder ordenar servicios, seleccione una categoría de antemano.',
    'Kunde wählen': 'Seleccionar cliente',
    'Umbuchen nach': 'Volver a reservar',
    'Kein Tisch gefunden': 'Ninguna tabla encontrada',
    'Legen Sie zuerst einen Bereich an.': 'Crear primero un área.',
    Email: 'Correo electrónico',
    'Vielen Dank': 'Muchas gracias',
    Verkaufszahlen: 'Cifras de ventas',
    Woche: 'Semana',
    'Ungültiges Format, nur jpg, png und gif ist erlaubt.': 'Formato no válido, solo se permiten jpg, png y gif.',
    Aktivieren: 'Activar',
    Deaktivieren: 'Desactivar',
    Beauftragt: 'Cantidad',
    Keine: 'Ninguno',
    Keiner: 'Ninguno',
    Keines: 'Ninguno',
    Wählen: 'Seleccionar',
    Sortieren: 'Ordenar',
    Speichern: 'Guardar',
    'Maximale Anzahl an Positionen erreicht.': 'Número máximo de unidades alcanzadas.',
    Unterzeichnet: 'Firmado',
    'E-Mail-Adressen': 'Las direcciones de correo electrónico',
    Hinzufügen: 'Agregar',
    Absenden: 'Enviar',
    'Druckauftrag erfolgreich abgesendet.': 'Trabajo de impresión enviado correctamente.',
    'Druckauftrag konnte nicht gesendet werden.': 'No se ha podido enviar el trabajo de impresión.',
    'Gutscheincode wurde bereits eingegeben': 'El código del vale ya ha sido introducido',
    'Alle auswählen': 'Seleccionar todo',
    'Alle abwählen': 'Deseleccionar todo',
    'Keine Empfänger ausgewählt': 'No se han seleccionado destinatarios',
    '%s Empfänger ausgewählt': '%s destinatario/s seleccionado/s',
    '%x von %y Artikel ausgewählt.': '%x de %y artículos seleccionados.',
    'Alle Artikel auswählen?': '¿Seleccionar todos los artículos?',
    '%x von %y Dienstleistungen ausgewählt.': '%x de %y servicios seleccionados.',
    'Alle Dienstleistungen auswählen?': '¿Seleccionar todos los servicios?',
    '%x von %y Einträgen ausgewählt.': '%x de %y entradas seleccionadas.',
    'Alle Einträge auswählen?': '¿Seleccionar todas las entradas?',
    'Nach dem @-Zeichen darf kein Umlaut verwendet werden.':
      'No se puede usar la diéresis después del signo "{\'@\'}".',
    'Bei der Erstellung der Rechnung ist ein Fehler aufgetreten. Bitte überprüfen Sie im Dashboard ihres Kartenterminals ihre letzte Überweisung.':
      'Se ha producido un error al crear la factura. Por favor, compruebe su última transferencia en el cuadro de mandos de su terminal de tarjetas.',
    Steuernummer: 'Número de identificación de extranjero (NIE)',
    'Diese Rechnung stornieren?': '¿Anular esta factura?',
    'Tisch Nr.': 'Mesa num.',
    'Die Trinkgeldfunktion kann in den %s ausgeblendet werden. (Achtung: bei Klick auf den Link verlässt man die Zahlungsseite)':
      'La función de propina se puede ocultar en el %s. (Atención: si hace clic en el enlace, sale de la página de pago)',
    'Gegeben Bar': 'Código indicado',
    'Retourgeld Bar': 'Devolución de dinero Efectivo',
    'Bewirtete Person(en)': 'Persona(s) a la que se le presta servicio',
    'Anlass der Bewirtung': 'Motivo del servicio',
    'Höhe der Aufwendungen': 'Importe del gasto',
    'Bei Bewirtung im Restaurant': 'Por el servicio de restaurante',
    'in anderen Fällen': 'en otros casos',
    Ort: 'Lugar',
    Rechnungsempfänger: 'Destinatario de la factura',
    'Bestellübersicht Nr.': 'Resumen del pedido nº.',
    'Dies ist kein Beleg / Rechnung': 'Esto no es un recibo / factura',
    'Bitte wählen Sie eine ZIP Datei.': 'Por favor, seleccione un archivo ZIP.',
    Beibehalten: 'Mantener',
    'Keine Ergebnisse gefunden': 'No se han encontrado resultados',
    'Suche schließen': 'Cerrar la búsqueda',
    Spalten: 'Columnas',
    'Ungültige Kartennummer. Bitte geben Sie 13-16 Ziffern ein.':
      'El número de tarjeta no es válido. Por favor, introduzca 13-16 dígitos.',
    'Ungültige Karteninformationen. Bitte prüfen Sie Ihre Eingaben.':
      'Información de la tarjeta no válida. Por favor verifique sus entradas.',
    'Ungültige Prüfziffer. Mindestens 3 Ziffern erforderlich.':
      'Número de cheque no válido. Se requieren al menos 3 dígitos.',
    'Ungültiges Ablaufdatum. Bitte prüfen Sie Ihre Eingaben.':
      'La fecha de caducidad no es válida. Por favor, compruebe sus datos.',
    'IBAN ungültig. Bitte prüfen Sie Ihre Eingaben.': 'El número IBAN no es válido. Por favor, compruebe sus datos.',
    'Rechnung wiederherstellen': 'Restablecer factura',
    'Diese Rechnung wiederherstellen?': '¿Restablecer esta factura?',
    Korrekturrechnung: 'Factura rectificativa',
    'Nach Wiederherstellung werden Sie zum Tisch weitergeleitet.':
      'Después de la recuperación, se le dirigirá a la mesa.',
    'Nach Wiederherstellung werden Sie zur Rechnungserstellung weitergeleitet.':
      'Después de la recuperación, será redirigido a la creación de facturas.',
    'derzeitige Sitzung': 'presente reunión',
    'Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.':
      'Ha ocurrido un error, por favor inténtalo de nuevo.',
    'Netzwerkfehler: Bitte aktualisieren Sie die Seite.': 'Error de red: Por favor, actualiza la página.',
    'Netzwerkfehler: Bitte versuchen Sie es erneut.': 'Error de red: Por favor, inténtalo de nuevo.',
    'Serverfehler: Bitte versuchen Sie es in einigen Momenten erneut.':
      'Error del servidor: Por favor, inténtelo de nuevo en unos momentos.',
    'Bitte geben Sie einen gültigen Gutscheincode ein und prüfen sie diesen vor dem einlösen.':
      'Por favor, introduzca un código de cupón válido y verifíquelo antes de canjearlo.',
    'Zum-Mitnehmen-Preise werden angewendet': 'Aplican precios para llevar',
    'In-Haus-Preise werden angewendet': 'Se aplican precios internos',
    'Die Bestellung befindet sich in der Warteschlange.': 'El pedido está en la cola.',
    'Kein Drucker gefunden': 'No se ha encontrado ninguna impresora',
    insgesamt: 'en total',
    'Export-Zeitüberschreitung. Bitte den Zeitraum und die Exportoptionen reduzieren.':
      'Tiempo de exportación excedido. Por favor, reduzca el período y las opciones de exportación.',
  },
  'pl-PL': {
    'Es ist ein Fehler aufgetreten': 'Wystąpił błąd',
    Gruppe: 'Grupa',
    Unterschrift: 'Podpis',
    Umsatz: 'Obrót',
    'Ungültiger Gutscheincode': 'Nieprawidłowy kod kuponu',
    Rabatt: 'Obniżka',
    'Die Dateigröße darf maximal 2MB betragen.': 'Maksymalna wielkość pliku to 2MB.',
    Zurück: 'Cofnij',
    'USt.': 'VAT',
    Artikel: 'Artykuł',
    UID: 'Numer VAT',
    Brutto: 'Brutto',
    Mitarbeiter: 'Współpracownik',
    Datum: 'Data',
    Storniert: 'Anulowano',
    'Lieferschein Nr.': 'Numer dowodu dostawy',
    'Tel.': 'Tel.',
    Web: 'Web',
    IBAN: 'IBAN',
    BIC: 'BIC',
    'Firmenbuch Nr.': 'Numer rejestracyjny firmy',
    'Rechnung stornieren': 'Anulować rachunek/fakturę',
    Kassier: 'Kasjer',
    'Erstellt von': 'Utworzone przez',
    Storno: 'Storno',
    Sequenznummer: 'Numer sekwencji',
    Signatur: 'Sygnatura',
    Anzahl: 'Liczba',
    'Angebot Nr.': 'Oferta nr',
    Dienstleistungen: 'Usługi',
    Einstellungen: 'Ustawienia',
    'Es muss entweder die Steuernummer oder die UID-Nummer angegeben werden.':
      'Należy podać numer podatkowy lub numer VAT EU.',
    'Die angegebene Datei ist leer.': 'Dodany plik jest pusty.',
    'Die Dateigröße darf maximal 5MB betragen.': 'Maksymalna wielkość pliku to 5MB.',
    'Bitte wählen Sie eine CSV Datei.': 'Prosimy o wybranie pliku CSV.',
    Am: 'O',
    Kasse: 'Kasa',
    Tisch: 'Stolik',
    Preis: 'Cena',
    KOPIE: 'KOPIA',
    'Bitte Eingabe überprüfen': 'Sprawdź wpisane dane',
    'Preis * Anzahl': 'Cena * ilość',
    'Preis * Anzahl - Rabatt in': 'Cena * ilość - rabat',
    '(Preis - Rabatt in %) * Anzahl': '(Cena - rabat w %) * ilość',
    'Um Artikel sortieren zu können, wählen Sie bitte zuvor eine Kategorie.':
      'Aby posortować artykuły, wybierz najpierw kategorię.',
    'Um Dienstleistungen sortieren zu können, wählen Sie bitte zuvor eine Kategorie.':
      'Aby posortować usługi, wybierz najpierw kategorię.',
    'Kunde wählen': 'Wybierz klientów',
    'Umbuchen nach': 'Zmień rezerwację na',
    'Kein Tisch gefunden': 'Nie znaleziono stolika',
    'Legen Sie zuerst einen Bereich an.': 'Najpierw wybierz zakres.',
    Email: 'E-mail',
    'Vielen Dank': 'Dziękujemy bardzo',
    Verkaufszahlen: 'Dane sprzedaży',
    Woche: 'Tydzień',
    'Ungültiges Format, nur jpg, png und gif ist erlaubt.': 'Nieprawidłowy format, akceptujemy tylko jpg, png i gif.',
    Aktivieren: 'Aktywować',
    Deaktivieren: 'Dezaktywować',
    Beauftragt: 'Zlecone',
    Keine: 'Brak',
    Keiner: 'Brak',
    Keines: 'Brak',
    Wählen: 'Wybierz',
    Sortieren: 'Sortować',
    Speichern: 'Zapisać',
    'Maximale Anzahl an Positionen erreicht.': 'Osiągnięto maksymalną ilość pozycji.',
    Unterzeichnet: 'Podpisano',
    'E-Mail-Adressen': 'Adresy e-mail',
    Hinzufügen: 'Dodać',
    Absenden: 'Wysłać',
    'Druckauftrag erfolgreich abgesendet.': 'Zadanie drukowania zostało pomyślnie przesłane.',
    'Druckauftrag konnte nicht gesendet werden.': 'Nie można przesłać zadania drukowania.',
    'Gutscheincode wurde bereits eingegeben': 'Kod bonu został już wprowadzony',
    'Alle auswählen': 'Wybierz wszystko',
    'Alle abwählen': 'Odznacz wszystko',
    'Keine Empfänger ausgewählt': 'Nie wybrano żadnego odbiorcy',
    '%s Empfänger ausgewählt': 'Wybrano %s odbiorców',
    '%x von %y Artikel ausgewählt.': 'Wybrano %x z %y artykułów.',
    'Alle Artikel auswählen?': 'Wybrać wszystkie artykuły?',
    '%x von %y Dienstleistungen ausgewählt.': 'Wybrano %x z %y usług.',
    'Alle Dienstleistungen auswählen?': 'Wybrać wszystkie usługi?',
    '%x von %y Einträgen ausgewählt.': 'Wybrano %x z %y wpisów.',
    'Alle Einträge auswählen?': 'Wybrać wszystkie wpisy?',
    'Nach dem @-Zeichen darf kein Umlaut verwendet werden.': "Po znaku {'@'} nie można użyć znaku umlaut.",
    'Bei der Erstellung der Rechnung ist ein Fehler aufgetreten. Bitte überprüfen Sie im Dashboard ihres Kartenterminals ihre letzte Überweisung.':
      'Wystąpił błąd podczas tworzenia rachunku/faktury. Sprawdź swój ostatni przelew w panelu terminala do kart płatniczych.',
    Steuernummer: 'Numer identyfikacji podatkowej',
    'Diese Rechnung stornieren?': 'Anulować fakturę/rachunek?',
    'Tisch Nr.': 'Numer stolika',
    'Die Trinkgeldfunktion kann in den %s ausgeblendet werden. (Achtung: bei Klick auf den Link verlässt man die Zahlungsseite)':
      'Funkcja napiwku może być ukryta w %s. (Uwaga: kliknięcie łącza powoduje opuszczenie strony płatności).',
    'Gegeben Bar': 'Wydana gotówka',
    'Retourgeld Bar': 'Zwrot pieniędzy - gotówka',
    'Bewirtete Person(en)': 'Osoba(y) obsługiwana(e)',
    'Anlass der Bewirtung': 'Powód gościnności',
    'Höhe der Aufwendungen': 'Kwota wydatków',
    'Bei Bewirtung im Restaurant': 'Podczas obsługi w restauracji',
    'in anderen Fällen': 'w innych przypadkach',
    Ort: 'Miejsce',
    Rechnungsempfänger: 'Odbiorca faktury',
    'Bestellübersicht Nr.': 'Podsumowanie zamówienia nr',
    'Dies ist kein Beleg / Rechnung': 'To nie jest paragon/faktura',
    'Bitte wählen Sie eine ZIP Datei.': 'Wybierz plik ZIP.',
    Beibehalten: 'Zachować',
    'Keine Ergebnisse gefunden': 'Nie znaleziono żadnych wyników',
    'Suche schließen': 'Zamknij wyszukiwanie',
    Spalten: 'Kolumny',
    'Ungültige Kartennummer. Bitte geben Sie 13-16 Ziffern ein.': 'Nieprawidłowy numer karty. Wprowadź 13-16 cyfr.',
    'Ungültige Karteninformationen. Bitte prüfen Sie Ihre Eingaben.':
      'Nieprawidłowe dane karty. Sprawdź wprowadzone dane.',
    'Ungültige Prüfziffer. Mindestens 3 Ziffern erforderlich.':
      'Nieprawidłowa cyfra kontrolna. Wymagane są co najmniej 3 cyfry.',
    'Ungültiges Ablaufdatum. Bitte prüfen Sie Ihre Eingaben.': 'Nieprawidłowa data ważności. Sprawdź wprowadzone dane.',
    'IBAN ungültig. Bitte prüfen Sie Ihre Eingaben.': 'IBAN jest nieprawidłowy. Sprawdź wprowadzone dane.',
    'Rechnung wiederherstellen': 'Przywróć rachunek',
    'Diese Rechnung wiederherstellen?': 'Przywrócić tę fakturę?',
    Korrekturrechnung: 'Faktura korygująca',
    'Nach Wiederherstellung werden Sie zum Tisch weitergeleitet.': 'Po przywróceniu zostaniesz przekierowany do stołu.',
    'Nach Wiederherstellung werden Sie zur Rechnungserstellung weitergeleitet.':
      'Po przywróceniu zostaniesz przekierowany do fakturowania.',
    'derzeitige Sitzung': 'obecne spotkanie',
    'Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.': 'Wystąpił błąd, proszę spróbować ponownie.',
    'Netzwerkfehler: Bitte aktualisieren Sie die Seite.': 'Błąd sieci: Proszę odświeżyć stronę.',
    'Netzwerkfehler: Bitte versuchen Sie es erneut.': 'Błąd sieci: Proszę spróbować ponownie.',
    'Serverfehler: Bitte versuchen Sie es in einigen Momenten erneut.':
      'Błąd serwera: Proszę spróbować ponownie za kilka chwil.',
    'Bitte geben Sie einen gültigen Gutscheincode ein und prüfen sie diesen vor dem einlösen.':
      'Proszę wprowadzić prawidłowy kod kuponu i sprawdzić go przed zrealizowaniem.',
    'Zum-Mitnehmen-Preise werden angewendet': 'Obowiązują ceny na wynos',
    'In-Haus-Preise werden angewendet': 'Obowiązują ceny wewnętrzne',
    'Die Bestellung befindet sich in der Warteschlange.': 'Zamówienie znajduje się w kolejce.',
    'Kein Drucker gefunden': 'Nie znaleziono drukarki',
    insgesamt: 'łącznie',
    'Export-Zeitüberschreitung. Bitte den Zeitraum und die Exportoptionen reduzieren.':
      'Przekroczono czas eksportu. Proszę zmniejszyć okres i opcje eksportu.',
  },
}
// !generated by script
