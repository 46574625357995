/**
 * It parses "helloCash Android App" or "helloCash iOS App" user agent, coming from the app.
 * In addition, when provided with additional parameters, these are parsed too (newer version of the apps - 04.2024 and future)
 *
 * Pattern: helloCash Android App/{App Version}/{OS Version}/{Brand}/{Model}
 * Example: helloCash Android App/1.19.3/11/Samsung/20pro
 * Example: helloCash iOS App/3.44//iPad/12
 *
 * OS Version, Brand, Model are optional, but the slash (/) count must be available.
 * For example: helloCash Android App/3.123
 * Gets parsed as: helloCash Android App
 * Because missing slashes.
 */

import { appTranslations } from '~src/globals/i18n/appTranslations.ts'
import { AndroidApp, iOSApp } from '~src/globals/variables.ts'
import { $ } from '~src/globals/jquery.ts'

type Brand = 'SUNMI' | 'Ciontek'
const supportedCamScan: Partial<Record<Brand, string[]>> = {
  SUNMI: ['V2s'],
  Ciontek: ['CS20', 'CS30', 'CS50'],
}

// will be set from add with function setMobileVersion
// Legacy!
let APP_VERSION_ANDROID: string | null = null
let APP_VERSION_IOS: string | null = null
let DEVICE_BRAND: string | null = null
let DEVICE_MODEL: string | null = null

interface UserAgentDetails {
  os: 'Android' | 'iOS' | null
  appVersion: string | null
  osVersion: string | null
  brand: string | null
  model: string | null
}

const getUserAgent = (): string | null => navigator.userAgent || null

const parseUserAgent = (): UserAgentDetails | null => {
  const pattern =
    /helloCash (?<os>Android|iOS) App(?:\/(?<appVersion>[\d.]+)\/(?<osVersion>[\d.]*)\/(?<brand>.*?)\/(?<model>.*))?/
  const userAgent = getUserAgent()
  if (!userAgent) {
    return null
  }

  const matches = userAgent.match(pattern)
  if (matches) {
    return {
      os: (matches[1] as 'Android' | 'iOS' | null) ?? null,
      appVersion: matches[2] || null,
      osVersion: matches[3] || null,
      brand: matches[4] || null,
      model: matches[5] || null,
    }
  }

  return null
}

export const isApp = (): boolean => parseUserAgent() !== null

export const getOs = (): UserAgentDetails['os'] | null => {
  return parseUserAgent()?.os ?? null
}

export const isIOS = (): boolean => {
  const userAgentDetails = parseUserAgent()
  return userAgentDetails !== null && userAgentDetails.os === 'iOS'
}

export const isAndroid = (): boolean => {
  const userAgentDetails = parseUserAgent()
  return userAgentDetails !== null && userAgentDetails.os === 'Android'
}

export const getAppVersion = (): string | null => {
  // On newer app versions, this is not necessary anymore, because it is set in the user agent
  const legacyAppVersion =
    APP_VERSION_ANDROID || APP_VERSION_IOS || window.APP_VERSION_ANDROID || window.APP_VERSION_IOS || null
  if (legacyAppVersion) {
    return legacyAppVersion
  }

  const userAgentDetails = parseUserAgent()
  return userAgentDetails ? userAgentDetails.appVersion : null
}

export const getAndroidVersion = (): string | null => {
  if (isIOS()) {
    return null
  }

  return getAppVersion()
}

export const getIOSVersion = (): string | null => {
  if (isAndroid()) {
    return null
  }

  return getAppVersion()
}

export const getOsVersion = (): string | null => {
  const userAgentDetails = parseUserAgent()
  return userAgentDetails ? userAgentDetails.osVersion : null
}

export const getBrand = (): string | null => {
  // On newer app versions, this is not necessary anymore, because it is set in the user agent
  // window.VARIABLE comes from backend. Necessary, because app calls javascript before the page javascript is completely parsed
  // Throwing errors in sentry, that (e.g.) setDeviceInfo is not defined.
  const legacyVariable = window.DEVICE_BRAND || DEVICE_BRAND
  if (legacyVariable) {
    return legacyVariable
  }

  const userAgentDetails = parseUserAgent()
  return userAgentDetails ? userAgentDetails.brand : null
}

export const getModel = (): string | null => {
  // On newer app versions, this is not necessary anymore, because it is set in the user agent
  // window.VARIABLE comes from backend. Necessary, because app calls javascript before the page javascript is completely parsed
  // Throwing errors in sentry, that (e.g.) setDeviceInfo is not defined.
  const legacyVariable = window.DEVICE_MODEL || DEVICE_MODEL
  if (legacyVariable) {
    return legacyVariable
  }

  const userAgentDetails = parseUserAgent()
  return userAgentDetails ? userAgentDetails.model : null
}

// can come from /model/classes/HtmlTagBuilderCommon.class.php
// Waiting for the new app versions to be released
declare global {
  interface Window {
    APP_VERSION_ANDROID: string | undefined
    APP_VERSION_IOS: string | undefined
    DEVICE_BRAND: string | undefined
    DEVICE_MODEL: string | undefined
  }
}

export const ScanType = {
  INVOICE: 'invoice',
  OFFER: 'offer',
  DELIVERY_NOTE: 'deliveryNote',
  ARTICLE: 'article',
  ARTICLE_SEARCH: 'articleSearch',
  SERVICE: 'service',
  SERVICE_SEARCH: 'serviceSearch',
  INVENTORY: 'inventory',
}

// Gets called in the apps "onPageFinished" event
export function getAppTranslations() {
  if (AndroidApp()) {
    AndroidApp()!.setAppTranslations(JSON.stringify(appTranslations()))
    return
  }

  if (iOSApp()) {
    const message = {
      job: 'setAppTranslations',
      translations: JSON.stringify(appTranslations()),
    }

    iOSApp()!.postMessage(message)
  }
}

/**
 * v1 is higher => compareVersions('3.4.5', '2.2.2') => 1
 * v2 is higher => compareVersions('3.4.5', '5.2.2') => -1
 * v1 and v2 same => compareVersions('3.4.5', '3.4.5') => 0
 */
function compareVersions(v1: string, v2: string) {
  try {
    if (v1 === v2) {
      return 0
    }
    const a_components = v1.split('.')
    const b_components = v2.split('.')
    const len = Math.min(a_components.length, b_components.length)

    for (let i = 0; i < len; i++) {
      if (parseInt(a_components[i]) > parseInt(b_components[i])) {
        return 1
      }

      if (parseInt(a_components[i]) < parseInt(b_components[i])) {
        return -1
      }
    }

    if (a_components.length > b_components.length) {
      return 1
    }

    if (a_components.length < b_components.length) {
      return -1
    }

    return 0
  } catch (e) {
    return -1
  }
}

async function hideLanguageSwitchOnUnsupported(): Promise<void> {
  if (!isApp()) {
    return
  }

  if (compareVersions(isAndroid() ? '1.7.9' : '1.6.8', getAppVersion()!) > 0) {
    $('.label-language').parent().hide()
  }
}

export function initializeAppWebView() {
  // Ensure that the app sents app version in user agent
  if (!getOsVersion()) {
    return
  }

  // Android versions below 7.1 are not supported.
  if (isAndroid() && compareVersions(getOsVersion()!, '7.1') < 0) {
    void showAppUpdateModal(true, 'sdk-version')
  }

  ensureAppVersion()
}

/**
 * setDeviceInfo is called in the app's onPageFinished event.
 * Calling this function is problematic, as javascript is not always available, when the app is calling it
 * See https://github.com/mRaPGmbH/hellocash/issues/4125
 *
 * To further support old apps, this function is not deleted. Future app versions will not call this function anymore.
 */
export function setDeviceInfo(brand: string, model: string): void {
  DEVICE_BRAND = brand
  DEVICE_MODEL = model
}

/**
 * setMobileVersion is called in the app's onPageFinished event.
 * Calling this function is problematic, as javascript is not always available, when the app is calling it
 * See https://github.com/mRaPGmbH/hellocash/issues/4125
 *
 * To further support old apps, this function is not deleted. Future app versions will not call this function anymore.
 */
export function setMobileVersion(os: string, version: string, androidSdkVersion?: string): void {
  // On old apps this is not set
  // Integer (SDK_INT) is passed as string
  const sdkVersion: number = androidSdkVersion ? +androidSdkVersion : 0

  switch (os) {
    case 'Android':
      APP_VERSION_ANDROID = version
      // On ancient apps, we did not send the androidSdkVersion. Force users to update the app so androidSdkVersion is sent.
      if (sdkVersion === 0) {
        void showAppUpdateModal(true, 'app-version')
        // 25 is the minimum supported SDK version. 7.1 is least supported Android version.
      } else if (sdkVersion < 25) {
        void showAppUpdateModal(true, 'sdk-version')
      }
      break
    case 'IOS':
      APP_VERSION_IOS = version
      break
  }

  ensureAppVersion()
}

function ensureAppVersion(): void {
  if (!isApp()) {
    return
  }

  if (!canUseNewSumupLogic()) {
    void showAppUpdateModal()
  }

  void hideAppleLoginOnUnsupported()
  void hideLanguageSwitchOnUnsupported()
  void hideCamScanOnUnsupported()
}

export function canUseCamScanLogic(): boolean {
  if (!isApp() || isIOS()) {
    return false
  }

  return compareVersions(getAppVersion()!, '1.9.3') > 0 && canDeviceCamScan()
}

function canUseNewSumupLogic(): boolean {
  if (!isApp()) {
    return false
  }

  return compareVersions(getAppVersion()!, isAndroid() ? '1.5.4' : '1.5.2') > 0
}

export function canUseNewCardTerminalLogic(): boolean {
  if (!isApp() || isIOS()) {
    return false
  }

  return compareVersions(getAppVersion()!, '1.7.10') > 0
}

export function canUseIsvAmount(): boolean {
  if (!isApp() || isIOS()) {
    return false
  }

  return compareVersions(getAppVersion()!, '1.8.2') > 0
}

export function canUsePrinterQueue(): boolean {
  if (!isApp()) {
    return false
  }

  return compareVersions(getAppVersion()!, isAndroid() ? '1.9.13' : '1.8.0') > 0
}

async function hideCamScanOnUnsupported(): Promise<void> {
  if (!isApp()) {
    return
  }

  if (compareVersions('1.9.4', getAppVersion()!) > 0 || !canDeviceCamScan() || isIOS()) {
    $('div[data-scan-button-container]').hide()
    $('.cam-scan').attr('style', 'margin-bottom: 0 !important')
  }
}

async function showAppUpdateModal(
  closeable: boolean = false,
  type: 'app-version' | 'sdk-version' = 'app-version',
): Promise<void> {
  if (closeable) {
    $('#modal-app-update .close').show()
  }

  if (isIOS()) {
    $('#app-update-ios').show()
  } else {
    $('#app-update-android').show()
  }

  $('#modal-app-update .' + type).show()

  if (!sessionStorage.getItem('app-update-modal')) {
    $('#modal-app-update').modal('show')
  }

  if (closeable) {
    sessionStorage.setItem('app-update-modal', 'true')
  }
}

async function hideAppleLoginOnUnsupported(): Promise<void> {
  if (!isApp()) {
    return
  }

  if (compareVersions(isAndroid() ? '1.6.5' : '1.5.15', getAppVersion()!) >= 0) {
    $('.social-apple').hide()
    $('.social-google').parent().addClass('col-xs-6').removeClass('col-xs-4')
    $('.social-facebook').parent().addClass('col-xs-6').removeClass('col-xs-4')
  }
}

function canDeviceCamScan(): boolean {
  const brand = getBrand()
  const model = getModel()

  if (!brand || !model) {
    return false
  }

  return Object.entries(supportedCamScan).some(
    ([brandKey, models]) => brand === brandKey && models.some((modelItem) => model.includes(modelItem)),
  )
}
